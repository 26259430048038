import React, { useEffect } from "react";
import { useState } from "react";
import {
  BsArrowLeftShort,
  BsArrowRepeat,
  BsArrowRightShort,
  BsFillSkipEndFill,
  BsFillSkipForwardBtnFill,
  BsFillSkipStartFill,
  BsRepeat1,
  BsShuffle,
  BsSkipBackwardBtnFill,
} from "react-icons/bs";
import { GiArtificialIntelligence } from "react-icons/gi";
import {
  FaPause,
  FaPlay,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import { TbRepeatOff, TbArrowsShuffle } from "react-icons/tb";
import { MdExplicit, MdOndemandVideo } from "react-icons/md";
import { RiPlayList2Fill, RiVideoFill } from "react-icons/ri";
import Marquee from "react-text-marquee";
import logo from "../../images/sonar_muse_logo_nav_invert1.png";
import invertLogo from "../../images/sonar_muse_logo_nav1.png";
import {
  convertDuration,
  getArtistsNames,
  getArtistsNames2,
  getPodcastersNames2,
  getSongTitle,
  getPodcastersNames,
  getObjectLength,
} from "../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  PODCASTER_SEARCH_TYPES,
} from "../../utils/Enum";
import ImageUtils from "../../utils/ImageUtils";
import { AudioPlayerContainer } from "../AudioPlayer/AudioPlayerElements";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import ScrollToTop from "../ScrollToTop";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import ToggleButton from "../ToggleButton";
import {
  Audio,
  AudioPlayerControls,
  AudioPlayerControlsWrapper,
  AudioPlayerCoverArt,
  AudioPlayerCoverArtWrapper,
  AudioPlayerDetailsWrapper,
  AudioPlayerDurationTimerWrapper,
  AudioPlayerPlaybackControlsWrapper,
  AudioPlayerSlider,
  AudioPlayerSliderWrapper,
  AudioPlayerSongDetailsText,
  AudioPlayerSongDetailsWrapper,
  AudioPlayerTimerText,
  AudioPlayerVolumeSlider,
  AudioPlayerWrapper,
  PreviewBadge,
  VolumeControlsWrapper,
} from "./AudioPlayerElements";
import { useAlert } from "../Alert/AlertContext";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SkeletonIcons,
  SkeletonText,
  SkeletonTextEnd,
} from "../Skeleton/SkeletonElements";
import { Img } from "../ContentList/ContentListElements";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AudioPlayer = ({
  timeJump,
  currentSong,
  isPlaying,
  duration,
  currentTime,
  progressBar,
  audioPlayer,
  changeRange,
  setTimeJump,
  volume,
  isRepeat,
  calcTime,
  backThirty,
  forwardThirty,
  isPodcast,
  isShuffle,
  nextSong,
  handleToggle,
  prevSong,
  playlist,
  setPlaylist,
  togglePlayPause,
  toggleMute,
  toggleVolume,
  isOpen,
  closeViewer,
  isMute,
  handleEnd,
  openViewer,
  isQueueLoaded,
}) => {
  const { currentUser, getUserSubscription, isUserSubscribed } = useAuth();
  const { defaultAlbumArt } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const { toggleModal, modalContent, openModal, closeModal } = useModal();
  const [isCountdown, setCoundown] = useState(false);

  /*  useEffect(() => {
    caches.keys().then(keys => {
      if(keys?.length > 0){

    
    caches.open('smMp').then((cache) => {
      return cache.match(playlist[currentSong]?.url, {}).then((res) => {
          console.log(res)
        if(res){
          var reader = res.body.getReader();
return reader.read().then(result => {
return result;
});
        }else{
          cache.add(playlist[currentSong]?.url)
          return false
        }
      }).then(data => {
        if(data){
          var blob = new Blob([data.value], { type: "audio/wav" });
var blobUrl = URL.createObjectURL(blob);
return blobUrl
        }else{
          console.log(false)
          cache.addAll(playlist.map(s => {
            console.log(s?.url)
            return s?.url
          })).then(() => {
            console.log(`added`)
          })
          return playlist[currentSong]?.url
        }
      })
      
    })}else{
      caches.open('smMp').then((cache) => {
      cache.addAll(playlist.map(s => {
        console.log(s?.url)
        return s?.url
      })).then(() => {
        console.log(`added`)
      })
      })
    }
  
  
  })
    return () => {}
  }, [currentSong])  */

  const validatePlay = async () => {
    let isPlay = false
    try {
      if (isPlaying) {
        handleToggle("play-pause", !isPlaying);
        return;
      } else {
        if (!currentUser?.subscription) {
          await getUserSubscription();
        }
        isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
          return handleToggle("play-pause", !isPlaying);
        } 
        if(isPlay === "Network Error"){
         return handleToggle("play-pause", !isPlaying);
        }
        else {
          openModal();
          modalContent(
            <>
              <StreamingSubscriptionModal
                onSuccess={() => {
                  getUserSubscription(currentUser?.uid);
                  handleToggle("play-pause", !isPlaying);
                  openModal();
                  return modalContent(
                    <OptionModal heading={"Subscription Enabled Sucessfully"}>
                      <HoverOption
                        onClick={() => {
                          closeModal();
                          modalContent();
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  );
                }}
                onFail={() => {
                  return modalContent(
                    <OptionModal heading={"Subscription Unsucessful"}>
                      <HoverOption
                        onClick={() => {
                          closeModal();
                          modalContent();
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  );
                }}
                cancel={() => {
                  closeModal();
                  modalContent();
                  return 
                }}
              />
            </>
          );
        }
      }
    } catch (err) {
      //console.log(err)
      if (isPlaying) {
        handleToggle("play-pause", !isPlaying);
        return;
      }
    }
  };

  return (
    <AudioPlayerContainer isLoaded={true}>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls onClick={openViewer}>
          <RiPlayList2Fill />
        </AudioPlayerControls>
        <VolumeControlsWrapper>
          <AudioPlayerControls onClick={toggleMute}>
            {isMute || volume === 0 ? (
              <FaVolumeMute />
            ) : volume * 100 < 50 ? (
              <FaVolumeDown />
            ) : (
              <FaVolumeUp />
            )}
          </AudioPlayerControls>
          <AudioPlayerVolumeSlider
            type="range"
            volume={isMute ? "0%" : `${volume * 100}%`}
            value={isMute ? 0 : volume * 100}
            onChange={toggleVolume}
          />
        </VolumeControlsWrapper>
      </AudioPlayerControlsWrapper>
      <AudioPlayerWrapper>
      <Audio
          ref={audioPlayer}
          src={playlist[currentSong]?.mp3Url ? playlist[currentSong]?.mp3Url : playlist[currentSong]?.url}
          loop={isRepeat === 2}
          preload={"auto"}
          volume={volume}
          muted={isMute || volume === 0}
          onEnded={handleEnd}
        />
        <AudioPlayerCoverArtWrapper>
          <AudioPlayerCoverArt
            onClick={openViewer}
            src={
              playlist[currentSong]?.albumArt
                ? playlist[currentSong]?.albumArt
                : playlist[currentSong]?.thumbnail
                ? playlist[currentSong]?.thumbnail
                : defaultAlbumArt
            }
            alt={""}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </AudioPlayerCoverArtWrapper>
        <AudioPlayerDetailsWrapper>
          <AudioPlayerSongDetailsWrapper>
            <AudioPlayerSongDetailsText
              onClick={() => {
                closeViewer();
                if (playlist[currentSong]?.songId) {
                  goTo(`/song/${playlist[currentSong]?.songId}`);

                  return;
                }
                if (playlist[currentSong]?.episodeId) {
                  /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                  goTo(`/episode/${playlist[currentSong]?.episodeId}`);
                  return;
                }
              }}
            >
              <>
                {playlist[currentSong]?.songId &&
                  (getSongTitle(playlist[currentSong])?.length > 30 ? (
                    <Marquee text={getSongTitle(playlist[currentSong])} />
                  ) : (
                    getSongTitle(playlist[currentSong])
                  ))}

                {playlist[currentSong]?.episodeId &&
                  (playlist[currentSong]?.episodeName?.length > 30 ? (
                    <Marquee text={playlist[currentSong]?.episodeName} />
                  ) : (
                    playlist[currentSong]?.episodeName
                  ))}
              </>
            </AudioPlayerSongDetailsText>
            <AudioPlayerSongDetailsText
              onClick={
                playlist[currentSong] &&
                (playlist[currentSong]?.artists?.length > 0 ||
                  playlist[currentSong]?.podcasters?.length > 0)
                  ? () => {
                      if (playlist[currentSong]?.songId) {
                        goTo(
                          `/artist/${playlist[currentSong]?.artists[0]?.artistId}`
                        );
                        return;
                      }
                      if (playlist[currentSong]?.episodeId) {
                        /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                        goTo(
                          `/podcaster/${playlist[currentSong]?.podcasters[0]?.podcasterId}`
                        );
                        return;
                      }
                    }
                  : {}
              }
            >
              <small onClick={closeViewer}>
                {playlist[currentSong]?.explicit && <MdExplicit />}
                {playlist[currentSong]?.video?.url && <RiVideoFill />}
                {playlist[currentSong]?.songId &&
                  (getArtistsNames2(
                    playlist[currentSong]?.artists,
                    ARTIST_SEARCH_TYPES.NAME
                  )?.length > 30 ? (
                    <Marquee
                      text={getArtistsNames(
                        playlist[currentSong]?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                    />
                  ) : (
                    getArtistsNames(
                      playlist[currentSong]?.artists,
                      ARTIST_SEARCH_TYPES.NAME
                    )
                  ))}
                {playlist[currentSong]?.episodeId &&
                  (getPodcastersNames2(
                    playlist[currentSong]?.podcasters,
                    PODCASTER_SEARCH_TYPES.NAME
                  )?.length > 30 ? (
                    <Marquee
                      text={getPodcastersNames(
                        playlist[currentSong]?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )}
                    />
                  ) : (
                    getPodcastersNames(
                      playlist[currentSong]?.podcasters,
                      PODCASTER_SEARCH_TYPES.NAME
                    )
                  ))}
              </small>
            </AudioPlayerSongDetailsText>
            <AudioPlayerDurationTimerWrapper
              onClick={() => setCoundown(!isCountdown)}
            >
              <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
                {isPlaying && calcTime(currentTime)}
              </AudioPlayerTimerText>
              <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
                {isPlaying && !isNaN(duration)
                  ? isCountdown
                    ? convertDuration(playlist[currentSong]?.duration ? playlist[currentSong]?.duration - currentTime : duration - currentTime)
                    : convertDuration(playlist[currentSong]?.duration ? playlist[currentSong]?.duration : duration)
                  : "0:00"}
              </AudioPlayerTimerText>
            </AudioPlayerDurationTimerWrapper>
          </AudioPlayerSongDetailsWrapper>
          <AudioPlayerSliderWrapper>
          <AudioPlayerSlider
              progressWidth={`${(currentTime / duration) * 100}%`}
              bufferWidth={progressBar?.current?.buffered?.length > 0 && `${(progressBar?.current?.buffered?.end(progressBar?.current?.buffered?.length - 1)* 100 / duration) }%`}
              type={"range"}
              ref={progressBar}
              onChange={changeRange}
            />
          </AudioPlayerSliderWrapper>
        </AudioPlayerDetailsWrapper>
      </AudioPlayerWrapper>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls
          isActive={isRepeat !== false}
          onClick={() => {
            handleToggle("repeat", isRepeat);
          }}
        >
          {!isRepeat && <TbRepeatOff />} {isRepeat === 1 && <BsArrowRepeat />}
          {isRepeat === 2 && <BsRepeat1 />}
        </AudioPlayerControls>
        <AudioPlayerPlaybackControlsWrapper
          isPodcast={playlist[currentSong]?.episodeId}
        >
          <AudioPlayerControls style={{fontSize: "1.4rem"}}
            onClick={() => {
              handleToggle("prev", true);
            }}
          >
            <BsFillSkipStartFill />
          </AudioPlayerControls>
          {playlist[currentSong]?.episodeId && (
            <AudioPlayerControls
              onClick={() => {
                backThirty();
              }}
            >
              <BsSkipBackwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls style={{fontSize: "1.4rem"}}
            onClick={() => {
              validatePlay();
            }}
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </AudioPlayerControls>
          {playlist[currentSong]?.episodeId && (
            <AudioPlayerControls style={{fontSize: "1.4rem"}}
              onClick={() => {
                forwardThirty();
              }}
            >
              <BsFillSkipForwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls style={{fontSize: "1.4rem"}}
            onClick={() => {
              handleToggle("next", true);
            }}
          >
            <BsFillSkipEndFill />
          </AudioPlayerControls>
        </AudioPlayerPlaybackControlsWrapper>
        <AudioPlayerControls
          isActive={isShuffle}
          onClick={() => {
            handleToggle("shuffle", !isShuffle);
          }}
        >
          <TbArrowsShuffle />
        </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
    </AudioPlayerContainer>
  );
};

export const AudioPlayerSkeleton = () => {
  return (
    <AudioPlayerContainer>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls>
          <SkeletonIcons />
        </AudioPlayerControls>
        <VolumeControlsWrapper>
          <SkeletonIcons />
        </VolumeControlsWrapper>
      </AudioPlayerControlsWrapper>
      <AudioPlayerWrapper>
        <AudioPlayerCoverArtWrapper>
          <AudioPlayerCoverArt
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        </AudioPlayerCoverArtWrapper>
        <AudioPlayerDetailsWrapper>
          <AudioPlayerSongDetailsWrapper>
            <AudioPlayerSongDetailsText>
              <>
                <SkeletonText />
              </>
            </AudioPlayerSongDetailsText>
            <AudioPlayerSongDetailsText>
              <small>
                <SkeletonTextEnd />
              </small>
            </AudioPlayerSongDetailsText>
            <AudioPlayerDurationTimerWrapper>
              <AudioPlayerTimerText></AudioPlayerTimerText>
              <AudioPlayerTimerText>0:00</AudioPlayerTimerText>
            </AudioPlayerDurationTimerWrapper>
          </AudioPlayerSongDetailsWrapper>
          <AudioPlayerSliderWrapper>
            <AudioPlayerSlider type={"range"} value={0} readOnly={true} />
          </AudioPlayerSliderWrapper>
        </AudioPlayerDetailsWrapper>
      </AudioPlayerWrapper>
      <AudioPlayerControlsWrapper>
        <AudioPlayerControls isActive={true}>
          <SkeletonIcons />
        </AudioPlayerControls>
        <AudioPlayerPlaybackControlsWrapper>
          <AudioPlayerControls>
            <SkeletonIcons />
          </AudioPlayerControls>

          <AudioPlayerControls>
            <SkeletonIcons />
          </AudioPlayerControls>
          <AudioPlayerControls>
            <SkeletonIcons />
          </AudioPlayerControls>
        </AudioPlayerPlaybackControlsWrapper>
        <AudioPlayerControls>
          <SkeletonIcons />
        </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
    </AudioPlayerContainer>
  );
};

export const AudioPreviewPlayer = ({
  timeJump,
  currentPreview,
  isPreviewPlaying, 
  previewDuration, 
  currentPreviewTime, 
  progressBar, 
  audioPlayer,
  changeRange,
  stopMainPlayer,
  setTimeJump,
  volume,
  isRepeat,
  calcTime, 
  backThirty, 
  forwardThirty,
  isPodcast,
  isShuffle,
  previewNext,
  handlePreviewToggle,
 
  previewPlaylist,

  togglePreviewPlay,
  toggleMute,
  toggleVolume,
 
  handleEnd,
  isMute,
  openViewer}) => {
    const { defaultAlbumArt } = ImageUtils();
    const { goTo } = ScrollToTop();
    const {theme} = useWebsiteTheme()
    const [isCountdown, setCoundown] = useState()
  useEffect(() => {
    return () => {
    };
  },);

 



  const chapters = [{
      start: 0,
      end: 0
  },
{
  start:0,
  end: 0
}]

return (
  <AudioPlayerContainer isLoaded={true}>
    <AudioPlayerControlsWrapper>
      <AudioPlayerControls>
        <RiPlayList2Fill />
      </AudioPlayerControls>
      <VolumeControlsWrapper>
        <AudioPlayerControls onClick={toggleMute}>
          {isMute || volume === 0 ? (
            <FaVolumeMute />
          ) : volume * 100 < 50 ? (
            <FaVolumeDown />
          ) : (
            <FaVolumeUp />
          )}
        </AudioPlayerControls>
        <AudioPlayerVolumeSlider
          type="range"
          volume={isMute ? "0%" : `${volume * 100}%`}
          value={isMute ? 0 : volume * 100}
          onChange={toggleVolume}
        />
      </VolumeControlsWrapper>
    </AudioPlayerControlsWrapper>
    <AudioPlayerWrapper>
    <Audio
        ref={audioPlayer}
        src={ `${previewPlaylist[currentPreview]?.mp3Url ? previewPlaylist[currentPreview]?.mp3Url : previewPlaylist[currentPreview]?.url}#t=${previewPlaylist[currentPreview]?.duration-30 > 0 ? previewPlaylist[currentPreview]?.duration-30 : 0}`}
        loop={isRepeat === 2}
        preload={"auto"}

        volume={volume}
        muted={isMute || volume === 0}
        onEnded={handleEnd}
      />
      <AudioPlayerCoverArtWrapper>
        <AudioPlayerCoverArt
          onClick={openViewer}
          src={
            previewPlaylist[currentPreview]?.albumArt
              ? previewPlaylist[currentPreview]?.albumArt
              : previewPlaylist[currentPreview]?.thumbnail
              ? previewPlaylist[currentPreview]?.thumbnail
              : defaultAlbumArt
          }
          alt={""}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        />
      </AudioPlayerCoverArtWrapper>
      <AudioPlayerDetailsWrapper>
       <AudioPlayerSongDetailsWrapper>
            <AudioPlayerSongDetailsText
              onClick={() => {
               
                if (previewPlaylist[currentPreview]?.songId) {
                  goTo(`/song/${previewPlaylist[currentPreview]?.songId}`);

                  return;
                }
                if (previewPlaylist[currentPreview]?.episodeId) {
                  /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                  goTo(`/episode/${previewPlaylist[currentPreview]?.episodeId}`);
                  return;
                }
              }}
            >
              <>
                {previewPlaylist[currentPreview]?.songId &&
                  (getSongTitle(previewPlaylist[currentPreview])?.length > 30 ? (
                    <Marquee text={getSongTitle(previewPlaylist[currentPreview])} />
                  ) : (
                    getSongTitle(previewPlaylist[currentPreview])
                  ))}

                {previewPlaylist[currentPreview]?.episodeId &&
                  (previewPlaylist[currentPreview]?.episodeName?.length > 30 ? (
                    <Marquee text={previewPlaylist[currentPreview]?.episodeName} />
                  ) : (
                    previewPlaylist[currentPreview]?.episodeName
                  ))}
              </>
            </AudioPlayerSongDetailsText>
            <AudioPlayerSongDetailsText
              onClick={
                previewPlaylist?.length > 0 &&
                (previewPlaylist[currentPreview]?.artists?.length > 0 ||
                  previewPlaylist[currentPreview]?.podcasters?.length > 0)
                  ? () => {
                      if (previewPlaylist[currentPreview]?.songId) {
                        goTo(
                          `/artist/${previewPlaylist[currentPreview]?.artists[0]?.artistId}`
                        );
                        return;
                      }
                      if (previewPlaylist[currentPreview]?.episodeId) {
                        /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })*/

                        goTo(
                          `/podcaster/${previewPlaylist[currentPreview]?.podcasters[0]?.podcasterId}`
                        );
                        return;
                      }
                    }
                  : () => {}
              }
            >
              {previewPlaylist?.length > 0 && <small>
                {previewPlaylist[currentPreview]?.explicit && <MdExplicit />}
                {previewPlaylist[currentPreview]?.video?.url && <RiVideoFill />}
                {previewPlaylist[currentPreview]?.songId &&
                  (getArtistsNames2(
                    previewPlaylist[currentPreview]?.artists,
                    ARTIST_SEARCH_TYPES.NAME
                  )?.length > 30 ? (
                    <Marquee
                      text={getArtistsNames(
                        previewPlaylist[currentPreview]?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}
                    />
                  ) : (
                    getArtistsNames(
                      previewPlaylist[currentPreview]?.artists,
                      ARTIST_SEARCH_TYPES.NAME
                    )
                  ))}
                {previewPlaylist[currentPreview]?.episodeId &&
                  (getPodcastersNames2(
                    previewPlaylist[currentPreview]?.podcasters,
                    PODCASTER_SEARCH_TYPES.NAME
                  )?.length > 30 ? (
                    <Marquee
                      text={getPodcastersNames(
                        previewPlaylist[currentPreview]?.podcasters,
                        PODCASTER_SEARCH_TYPES.NAME
                      )}
                    />
                  ) : (
                    getPodcastersNames(
                      previewPlaylist[currentPreview]?.podcasters,
                      PODCASTER_SEARCH_TYPES.NAME
                    )
                  ))}
              </small>}
              {previewPlaylist?.length === 0 && <div style={{display: 'flex', width: '100%', height: '24px', marginLeft: '40%', opacity: '0.8' }} > 
                <img alt={'Sonar Muse'} style={{display: 'flex', width: '20px', height: '20px', justifySelf: 'center', alignSelf: 'center' }} src={theme === 'dark' ?  invertLogo : logo}/>
                
                </div>}
            </AudioPlayerSongDetailsText>
            <AudioPlayerDurationTimerWrapper
              onClick={() => setCoundown(!isCountdown)}
            >
              <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
                {/* isPreviewPlaying && calcTime(currentPreviewTime) */} 
              </AudioPlayerTimerText>
              <AudioPlayerTimerText onClick={() => setCoundown(!isCountdown)}>
                {/* isPreviewPlaying && !isNaN(previewDuration)
                  ? isCountdown
                    ? convertDuration(previewDuration - currentPreviewTime )
                    : convertDuration(previewDuration)
                  : "" */}
              </AudioPlayerTimerText>
              
            </AudioPlayerDurationTimerWrapper>
           { previewPlaylist?.length > 0 && <PreviewBadge>Preview</PreviewBadge>}
          </AudioPlayerSongDetailsWrapper>
      {/*   <AudioPlayerSliderWrapper>
        <AudioPlayerSlider
            readOnly={true}
            progressWidth={`${(currentPreviewTime / previewDuration) * 100}%`}
            bufferWidth={`${((currentPreviewTime / previewDuration)* 100) }%`}
            type={"range"}
            ref={progressBar}
          />
        </AudioPlayerSliderWrapper>*/}
      </AudioPlayerDetailsWrapper> 
    </AudioPlayerWrapper>
    <AudioPlayerControlsWrapper>
        <AudioPlayerControls
          isActive={isRepeat !== false}
          onClick={() => {
            handlePreviewToggle("repeat", isRepeat);
          }}
        >
          {!isRepeat && <TbRepeatOff />} {isRepeat === 1 && <BsArrowRepeat />}
          {isRepeat === 2 && <BsRepeat1 />}
        </AudioPlayerControls>
        <AudioPlayerPlaybackControlsWrapper
          isPodcast={previewPlaylist[currentPreview]?.episodeId}
        >
          <AudioPlayerControls style={{fontSize: "1.4rem"}}
            onClick={() => {
              handlePreviewToggle("prev", true);
            }}
          >
            <BsFillSkipStartFill />
          </AudioPlayerControls>
          {previewPlaylist[currentPreview]?.episodeId && (
            <AudioPlayerControls
              onClick={() => {
                backThirty();
              }}
            >
              <BsSkipBackwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls style={{fontSize: "1.4rem"}}
            onClick={() => {
            togglePreviewPlay()
            }}
          >
            {isPreviewPlaying ? <FaPause /> : <FaPlay />}
          </AudioPlayerControls>
          {previewPlaylist[currentPreview]?.episodeId && (
            <AudioPlayerControls style={{fontSize: "1.4rem"}}
              onClick={() => {
                forwardThirty();
              }}
            >
              <BsFillSkipForwardBtnFill />
            </AudioPlayerControls>
          )}
          <AudioPlayerControls style={{fontSize: "1.4rem"}}
            onClick={() => {
              handlePreviewToggle("next")
            }}
          >
            <BsFillSkipEndFill />
          </AudioPlayerControls>
        </AudioPlayerPlaybackControlsWrapper>
        <AudioPlayerControls
          isActive={isShuffle}
          onClick={() => {
            handlePreviewToggle("shuffle", !isShuffle);
          }}
        >
          <TbArrowsShuffle />
        </AudioPlayerControls>
      </AudioPlayerControlsWrapper>
  </AudioPlayerContainer>
);

}

export default AudioPlayer;
