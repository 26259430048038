import { BsThreeDots } from "react-icons/bs";
import Marquee from "react-text-marquee";
import styled from "styled-components";
import hexToRgbA, { hexToRgba, lightOrDark } from "../HexToRGBA";
import { Nav, NavLink } from "../NavLink/NavLinkElements";

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const MusicPageContainer = styled.div`
    display: flex;
    background: ${props => props.theme?.background};
    justify-content: center;
    max-width: 100%;
`

export const MusicPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    transition: all 0.2s;
    background: ${props => props.theme.mainBg};
    filter: drop-shadow(1px 2px 2px rgba(15,15,15,0.76));
    color: ${props => props.theme.secondaryBg};

`

export const MusicDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;
    margin: 1% 0;
    justify-content: center;
    border-bottom: 1px solid ${props => hexToRgba(props.theme.secondaryBg, 0.1)};
    @media screen and (max-width: 960px) {
        flex-wrap: wrap;
      }
    
`

export const MusicInfoWrapper = styled.section`
    display: flex;
    flex-direction: column;
    padding: 1% 5%;
    width: 100%;
`

export const MusicHeaderWrapper = styled.div`
    diplay: flex;
    margin-bottom: 5%;
    flex-direction:row;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 8% 3% 5% 3%;
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
    background: ${props => `linear-gradient(180deg, ${props.color ? props.color : props.theme.mainColor} 0%, rgba(84, 120, 154, 0.6232) 100%)`};
    color: ${props => props.theme.secondaryBg};
`

export const MusicHeadingContainer = styled.div`
    display: flex;
    margin-bottom: 5%;
    overflow:hidden;
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
    justify-content: flex-start;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        flex-direction: column;
        justify-content: center;
      }

`

export const MusicHeadingBgImage = styled.div`
    display: flex;
    background: ${props=> props.color} url(${props => props.src}) center fixed no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-bottom: 2%;
    justify-content: center;
    filter: blur(30px);
    -webkit-filter: blur(30px);
    pointer-events: none;

`

export const MusicLyricsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  flex-direction: column;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding: 1.5%;
  white-space: break-spaces;
  text-align: center;
`

export const MusicHeadingWrapper = styled.div`
    display: flex;
    padding: 5%;
    width: 100%;
    background-color: ${props => hexToRgba(props.theme.mainBg, 0.5)};
    z-index: 15;
    justify-content: flex-start;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        flex-direction: column;
        justify-content: center;
    }

`

export const MusicHeadingImageWrapper = styled.div`
    display: flex;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: center;
      }
`
export const MusicHeadingImage = styled.img`
    display: flex;
     width: 20rem;
    height: 20rem;
    background: ${props => props.color} url(${props => props.src});
    background-size: cover;
    border-radius: 5px;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);
    &:hover{
        transform: scale(1.03);

    }
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        width: 15rem;
        height: 15rem;
      }
    
`

export const MusicHeading = styled.div`
    display: flex;
    align-items:center;
    font-weight: 700;
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
    font-size: 2rem; 
    padding: 0% 1%;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: center;
        text-align: center;
        font-size: 1.2rem; 
      }
    @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
        justify-content: center;
        text-align: center;
        font-size: 0.8rem; 
      }
`
export const MusicSubheading = styled.div`
    display: flex;
    color: ${props => props.theme.secondaryColor};
    align-items:center;
    font-size: 1rem; 
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
    
    margin-bottom: 1%;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: center;
        font-size: 0.8rem; 
      }
`

export const MusicHeadingTextWrapper = styled.div`
      display: flex;
      flex-direction: column;
      padding: 2%;
      width: 100%;
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: center;
      }
`

export const MusicHeadingSmall = styled.small`
      display: flex;
    padding: 0% 1%;
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: center;
      }
`


export const NftBadgeWrapper = styled.div`
      display: flex; 
      font-size: 1.5rem;
      color: ${props => props.theme.secondaryBg};
      padding: 0% 0.5%;
`
export const NftLogo = styled.img`
      display: flex; 
      width: 2rem;
      border-radius: 30%;
      background: ${props => props.theme.secondaryBg};
      height: 2rem;

`

export const SubHeading = styled.div`
      display: flex;
      justify-self: flex-end;
      align-content: center;
      font-size: 1.5rem;
      font-weight: 300;
      padding: 1%;
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: center;
        font-size: 0.9rem;
      }
`

export const SongDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
`

export const SongDetailsText = styled.small`
    display: flex;
    padding: 2%;
`

export const MusicPlayerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1%;
    justify-content: space-evenly;
    justify-items: flex-start;
    align-items: center;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        flex-direction: column;
      }
`

export const MusicInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1%;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        align-items: center;
      }
    
`

export const MusicPlayPauseButton = styled.button`
display: flex;
border: none;
border-radius: 15px;
margin-left: 2%;
margin-bottom: 2%;
white-space: nowrap;
font-size: 1.2rem;
align-content: center;
justify-content: space-evenly;
border: ${props => props?.transparent ? `${props.theme.secondaryBg} 1px solid` : 'none'};
background: ${props => props?.transparent ? hexToRgba(props.theme.secondaryBg, 0.05) : props.theme.secondaryBg};
cursor: pointer;
padding: 1% 4%;
color: ${(props) => props?.transparent ? props.theme.secondaryBg : props.theme.mainBg};
justify-items: space-evenly;

align-items: center;
&:hover {
  transition: all 0.3s ease-in-out;
  background: ${props => props?.transparent ? props.theme.mainColor : lightOrDark(props.theme.mainColor) === "dark" ? hexToRgba(props.theme.secondaryColor, 0.5) : props.theme.secondaryColor};
  color: ${(props) => props?.transparent ? ((lightOrDark(props.theme.mainColor) === "dark" && lightOrDark(props.theme.secondaryBg) === "dark") || (lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.secondaryBg) === "light")) ? props?.theme?.mainBg :  props.theme.secondaryBg :  props.theme.mainColor};  
}

  @media screen and (max-width: 480px) {
    margin-top: 0;
    font-size: 1rem;
  }

`
export const ProgressBarWrapper = styled.div`
display: flex;
position: relative;
padding: 2%;
margin: 1% 2%;
align-items: center;
flex: 1 1 60%;
`

export const ProgressBar = styled.input`
display: flex;
width: 100%;


/*Safari settings */
    ::-webkit-slider-runnable-track {
    width: 100%;
    }
/*firefox settings */
::-moz-range-track {
      background: ${(props) => props.theme.mainBg};
    }

::-moz-focus-outer{
        border: 0;
    }    
    
`


export const MusicNav = styled(Nav)`
    margin: 2% 0;
`

export const MusicNavLink = styled(NavLink)`
`

export const MusicDetailsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`

export const MusicAboutWrapper = styled.small`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: space-around;
    text-align: center;
    font-style: italic;
    margin-bottom: 1%;
    padding: 1%;
    white-space: normal;
    color: ${props => props?.error ? props.theme.errorColor : props.theme.secondaryBg};

`

export const MusicPriceWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 5%;
`

export const Text = styled.text`
    display: flex;
    padding: 2%;
    align-items: center;
    white-space: nowrap;
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

`



export const OptionButton = styled.div`
    display:flex;
    flex-direction: row;
    color: ${(props) => props.theme.mainBg};
    font-size: 2rem;
    height: 100%;
    padding: 1%;
    margin-bottom:2%;
    margin-left: 2%;
    justify-self: center;
    align-self: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        transition: all 0.3s ease-in-out;
        transform: scale(0.95);
        color: ${(props) => props.theme.mainColor};
      }
`

export const OptionMenuButton = styled(BsThreeDots)`
border-radius: 50%;
background-color: ${props => hexToRgba(props.theme.secondaryBg, 0.7)};
padding: 5%;
margin-bottom:2%
margin-left: 2%;
justify-self: center;
align-self: center;
&:hover {
    background-color: ${props => hexToRgba(props.theme.accentColor, 1)};
  }
`


export const OptionText = styled.text`
    display: none;
    align-items: center;
    filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};
    ${OptionButton}: hover &{
        display: flex;
    }
 
`
export const DetailsWrapper = styled.div`
      display: flex;
      padding: 2%;
      width: 50%;
      align-items: center;
      border-radius: 5px;
      justify-content: center;
      background-color: ${props => hexToRgba(props.theme.accentColor, 0.6)};
      @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        width: 100%;
        justify-content: flex-start;
      }

`
export const Img = styled.img`
      display: flex-box;
      height: 5rem;
      width: 5rem;
      padding: 2%;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;
      
`

export const Select = styled.select`
    display: flex;
    background: transparent;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border: 1px ${props => props.theme.secondaryBg} solid;

    cursor: pointer;
    color: ${props => props.theme.secondaryBg};

`

export const Option = styled.option`
    display: flex;
    background: ${props => props.theme.mainBg};
    width: 100%;
    &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.02);
  }
`

export const AlbumArtContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
    flex-wrap: wrap;
    
`

export const AlbumArtWrapper =styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 2%;
    
    
`

export const PurchaseButton = styled.div`
  display: flex;
  align-items: center;
  border: solid 2px ${props => props.color ? props.color : props.theme.mainColor};
  background: transparent;
  justify-content: center;
  padding: 1%;
  margin: 1% 2%;
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.02);
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

`

export const AlbumArt = styled.img`
    display: flex;
    cursor: pointer;
    position: relative;
    width: 30rem;
    height: 30rem;
    border-radius: 25px;
    border: 2px groove ${props => props => props.color ? props.color : props.theme.mainColor};
    @media screen and (max-width: 768px) {
        width: 20rem;
        height: 20rem;
      }
`

export const MusicOptionsContainer = styled.div`
    display: flex;
    border: none;
    width: 100%;
    align-content: center;
    justify-content: flex-start;
    padding: 2% 0%;
    background-color: transparent;
    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        width: 100%;
      }

    `

  export const MusicOptionsWrapper = styled.div`
      display: flex;
      padding: 2%;
      width: 100%;
      flex: 1 1 100%;


    @media screen and (max-width: ${MEDIA_SCREEN.TABLETS}px) {
      display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        width: 100%;
      }
  `

export const MusicContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CreditsHeading = styled.div`
display: flex;
padding: 1%;
font-size: 1.2rem
align-items: center;
white-space: nowrap;
filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

`

export const CreditsText = styled.div`
display: flex;
padding-left: 1%;
font-size: 1.2rem
align-items: center;
color: ${props => props.theme.secondaryColor};
filter: ${props => `drop-shadow(0px 4px 4px ${props.theme.mainBg})`};

`

