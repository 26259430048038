import React, { useCallback, useEffect, useState } from "react";
import ContentList from "../ContentList";
import ContentWrapper from "../ContentWrapper";
import {
  AlbumArt,
  albumArt,
  AlbumArtContainer,
  albumArtContainer,
  AlbumArtWrapper,
  albumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicPriceWrapper,
  MusicSubheading,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  ProgressBar,
  ProgressBarWrapper,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import { FaPlay, FaPause, FaRegMoneyBillAlt, FaShare } from "react-icons/fa";
import { filterLikes, getObjectLength, getSongTitle, timestampToDate } from "../../utils";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { MdBookmarkBorder } from "react-icons/md";
import { GoReport } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Slider } from "../Slider";
import { AiOutlineEye } from "react-icons/ai";
import defaultImg from "../../images/sm_album_art_placeholder.png";
import {MusicOptionsModal, PlaylistCollaboratorsViewer } from "../MenuOptions";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import { useAudio, usePreviewAudio } from "../AudioPlayer/context/AudioContext";
import {
  ALERT_TYPES,
  CONTENT_TYPES,
  MUSIC_PLAYING_FROM,
  SLIDER_TYPES,
} from "../../utils/Enum";
import { BsShuffle, BsThreeDots } from "react-icons/bs";
import { Theme } from "../WebsiteThemeElements";
import { hexToRgba } from "../HexToRGBA";
import { useAlert } from "../Alert/AlertContext";
import MusicEditPage from "../MusicEditPage";
import CommentForm from "../CommentForm";
import { useModal } from "../Modal/ModalContext";
import CommentsSection from "../CommentsSection";
import { shuffleSongs } from "../../utils/MusicUtils";
import { CustomButton } from "../ButtonElement";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { getAddressName } from "../../utils/nfTokensUploads";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import SongList, { CollectionSongList } from "../ContentList/SongList";
import { useUser } from "../../hooks/useUser";
import { getResizedImage } from "../../utils/FileUploadsUtils";
import { NavContainer } from "../NavLink/NavLinkElements";
import PlaylistStats from "./Statistics/PlaylistStats";

export default function PlaylistPage({
  id,
  playlist,
  isSongPlaying,
  handleToggle,
  currentSong,
  audioPlayer,
  deletePlaylist,
  query,
  location,
  updateWebPageIcon
}) {
  const {currentPreview, setCurrentPreview,  setPreviewPlaylist, play, handlePreviewToggle, togglePreviewPlay, isPreviewPlaying} = usePreviewAudio()
  const { currentUser, isUserSubscribed, getUserSubscription } = useAuth();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
    const {getPlaylistsByUser, getCollaborativePlaylistsByUser} = useUser({})
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const [isEditing, setEditing] = useState(false);
  const [playlists, setPlaylists] = useState()
  const [currentTab, setTab] = useState("music")
  const [collabPlaylists, setCollabPlaylists] = useState()
  const [isLoaded, setLoaded] = useState(false)
  const [isCPLoaded, setCPLoaded] = useState(false)
  const [curatorName, setCuratorName] = useState();
  const [isPlaying, setPlaying] = useState(false)
  const { setCurrentSong, setPlaylist, isQueueLoaded } = useAudio();
  const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const isPlayingFrom = useCallback(() => {
      if (
        localStorage?.getItem("isPlayingFrom") &&
        JSON.parse(localStorage?.getItem("isPlayingFrom"))?.playingFrom ===
          MUSIC_PLAYING_FROM.PLAYLIST &&
        JSON.parse(localStorage?.getItem("isPlayingFrom"))?.id ===
          playlist.playlistId
      ) {
        return true;
      }
      return false;
    }, [playlist])

    useEffect(() => {
      updateWebPageIcon(playlist?.playlistArt)
      return () => {}
    },[])

    useEffect(() => {
      getPlaylistsByUser(playlist?.curator?.curatorId,0)
    .then((pl) => {
       if (pl && !pl?.error) {
        setPlaylists(pl)
        
      }
     setLoaded(true)
    })
    .catch((err) => {
      //console.log(err);
      setLoaded(true)
      return;
    });
      getCollaborativePlaylistsByUser(playlist?.curator?.curatorId, 0).then(cPl => {
          if(cPl && !cPl.error){
              setCollabPlaylists(cPl)
              
          }
          setCPLoaded(true)
    }).catch((err) => {
      setCPLoaded(true)
    })
      return () => {}
  },[playlist])

  useEffect(() => {
    

    if (params.has("edit")) {
      if (
        currentUser?.uid === playlist?.curator?.curatorId ||
        playlist?.collaborators?.includes(currentUser?.uid)
      ) {
        setEditing(true);
      }
    } else {
      setEditing(false);
    }
    return () => {};
  }, [location, params, isEditing]);

 useEffect(() => {
  if(isSongPlaying && isPlayingFrom()){
    setPlaying(true)
  }else{
    setPlaying(false)
  }

 },[playlist, isSongPlaying, isPlayingFrom])

  useEffect(() => {
    getAddressName(playlist?.curator?.curatorId).then((curator) => {
      if(getObjectLength(curator) > 0){
        return setCuratorName(curator?.name);
      }else{
        setCuratorName(curator)
      }
      
    });
    return () => {};
  }, [playlist]);

  const playSong = async(i) => {
    if (currentUser?.uid) {
      let isPlay = await isUserSubscribed()
      if (isPlay && isPlay !== "Network Error") {
        if(isQueueLoaded){
        if (playlist?.songs?.length > 0) {
          let playingFrom = {
            playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
            id: playlist.playlistId,
          };
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          setPlaylist(
            playlist?.songs?.map((s) => {
              return { ...s, playingFrom: playingFrom };
            })
          );
          setCurrentSong(i);
          handleToggle("play");
        }
        return;
      }}
    }
    return;
  };

  const togglePlay = async (pl) => {
    if (currentUser?.uid) { 
      let playingFrom = {
          playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
          id: playlist.playlistId,
        };
        setPlaylist(pl);
        let isPlay = await isUserSubscribed()
        if (isPlay && isPlay !== "Network Error") {
        if(isQueueLoaded){
       if(!isSongPlaying && !isPlayingFrom()){
          handleToggle("play", true);
          setPlaying(!isSongPlaying)
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          return
        }
       if(isSongPlaying && !isPlayingFrom()){
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        setPlaying(true)
          handleToggle("play", true);
          return
        }
        if (isSongPlaying && isPlayingFrom()) {
          handleToggle("play-pause", false);
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          setPlaying(false)
          return;
        }
        if (!isSongPlaying && isPlayingFrom()) {
          setPlaying(!isSongPlaying)
          handleToggle("play", true);
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));

          return;
        }
        localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
        setPlaying(!isSongPlaying)
        setCurrentSong(0);
        handleToggle("play");
        return;}
      }  if(isPlay === "Network Error"){
        addAlert({
          title: "Network Error",
          type: ALERT_TYPES.DANGER,
          message:
            'Cannot play music. Please check your connection',
        })
        if (
          isSongPlaying
        ) {
          setPlaying(false)
          handleToggle("play-pause", !isSongPlaying)
        }
       }
       else {
        if (
          isSongPlaying
        ) {
          setPlaying(false)
          handleToggle("play-pause", !isSongPlaying)
        }
        openModal();
        modalContent(
          <>
            <StreamingSubscriptionModal
              onSuccess={() => {
                getUserSubscription(currentUser?.uid);
                handleToggle("play-pause", !isSongPlaying);
                setPlaying(!isSongPlaying)
                localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
                modalContent(
                  <OptionModal heading={"Subscription Sucessful"}>
                    <HoverOption onClick={() => {
                        closeModal();
                        modalContent();
                      }}>OK</HoverOption>
                  </OptionModal>
                );
              }}
              onFail={() => {
                modalContent(
                  <OptionModal heading={"Subscription Unsucessful"}>
                    <HoverOption
                      onClick={() => {
                        closeModal();
                        modalContent();
                      }}
                    >
                      OK
                    </HoverOption>
                  </OptionModal>
                );
              }}
              cancel={() => {
                closeModal();
                modalContent();
              }}
            />
          </>
        );
      }
    } else {
      addAlert({
        title: "Not Logged In",
        type: ALERT_TYPES.WARNING,
        message: "You need to log in to play this playlist",
      });
    }
  };


  const shufflePlaylist = async () => {
    if (playlist?.songs?.length > 0) {
      if (currentUser?.uid) {
        
        if ((await isUserSubscribed())) {
          if(isQueueLoaded){
          let playingFrom = {
            playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
            id: playlist?.playlistId,
          };
          localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
          let songs = shuffleSongs(
            playlist?.songs.map((p) => {
              return { ...p, playingFrom: playingFrom };
            })
          );
          setPlaylist(songs);
          setCurrentSong(0);
          await handleToggle("play")
          setPlaying(true);}
        } else {
          if (
            isSongPlaying
          ) {
            setPlaying(false)
            handleToggle("play-pause", !isSongPlaying)
          }
          openModal();
          modalContent(
            <>
              <StreamingSubscriptionModal
                onSuccess={() => {
                  getUserSubscription(currentUser?.uid);
                  setPlaying(!isSongPlaying)
                  handleToggle("play-pause", !isSongPlaying);
                  modalContent(
                    <OptionModal heading={"Subscription Unsucessful"}>
                      <HoverOption onClick={closeModal()}>OK</HoverOption>
                    </OptionModal>
                  );
                }}
                onFail={() => {
                  modalContent(
                    <OptionModal heading={"Subscription Unsucessful"}>
                      <HoverOption
                        onClick={() => {
                          closeModal();
                          modalContent();
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  );
                }}
                cancel={() => {
                  closeModal();
                  modalContent();
                }}
              />
            </>
          );
        }
      }
    } else {
      addAlert({
        title: "Not Logged In",
        type: ALERT_TYPES.WARNING,
        message: "You need to log in to play this playlist",
      });
    }
  };

  const togglePreview = async (pl) => {

    // if(isPlaying && subscribed to coil)
    if(pl?.length > 0){
      setPreviewPlaylist(pl);
      if(!isPreviewPlaying){
        await togglePreviewPlay("play-pause");  
      }
      play()
      setCurrentPreview(0); 
      return
    }

 
};


const togglePreviewSong = async(i) => {
  
  setPreviewPlaylist(playlist?.songs); 
  if(!isPreviewPlaying){
  await togglePreviewPlay("play-pause");
}
play()
  setCurrentPreview(i);
 
  return
  

};


  return (
    <MusicPageWrapper>
      <MusicHeadingContainer>
        <MusicHeadingBgImage
          color={playlist?.color}
          src={
            playlist?.playlistArt
              ? playlist.playlistArt
              : playlist?.songs && playlist?.songs[0]?.albumArt
              ? playlist?.songs[0]?.albumArt
              : defaultImg
          }
          alt={playlist.playlistName}
        ></MusicHeadingBgImage>
        <MusicHeadingWrapper>
          <MusicHeadingImageWrapper>
            <MusicHeadingImage
             onContextMenu={(e) => {
              e.preventDefault();
            }}
              color={playlist?.color}
              src={
                playlist?.playlistArt
                  ? playlist.playlistArt
                  : playlist?.songs && playlist?.songs[0]?.albumArt
                  ? playlist?.songs[0]?.albumArt
                  : defaultImg
              }
              alt={playlist.playlistName}
            />
          </MusicHeadingImageWrapper>
          <MusicHeadingTextWrapper>
            <SubHeading>
              <small>Playlist</small>
            </SubHeading>
            <MusicHeading>{playlist?.playlistName}</MusicHeading>
            <MusicSubheading>
              <MusicHeading
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/u/${playlist?.curator?.curatorId}`)}
              >
                {curatorName && curatorName !== playlist?.curator?.curatorId
                  ? curatorName
                  : `${playlist?.curator?.curatorId.substring(
                      0,
                      8
                    )}...${playlist?.curator?.curatorId.substring(
                      playlist?.curator?.curatorId.length - 5,
                      playlist?.curator?.curatorId.length
                    )}`}
              </MusicHeading>
            </MusicSubheading>
            <MusicHeadingSmall>
              {`${playlist?.songs?.length} track(s)`}○
              {new Date(timestampToDate(playlist?.datePublished)).getFullYear()}
              {playlist?.collaborators?.length > 0
                && <div style={{cursor:"pointer"}}  onClick={() => {
                  openModal();
                  modalContent(
                    <PlaylistCollaboratorsViewer playlist={playlist}/>
                  );
                }}>{`○${playlist?.collaborators?.length} Collaborator(s)`}
                </div>}
            </MusicHeadingSmall>
            <MusicOptionsContainer>
              {currentUser?.uid && playlist?.songs?.length > 0 && !isEditing && (
                <>
                  <MusicPlayPauseButton
                    onClick={() => {
                      if (playlist?.songs?.length > 0) {
                        let songs = playlist?.songs.map((p) => {
                          return {
                            ...p,
                            playingFrom: {
                              playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
                              id: playlist?.playlistId,
                            },
                          };
                        });
                        togglePlay(songs);
                      }
                    }}
                  >
                    {isPlaying ? (
                      <>
                        <FaPause /> Pause
                      </>
                    ) : (
                      <>
                        <FaPlay
                          style={{
                            position: "relative",
                            left: "1%",
                            marginRight: "2%",
                          }}
                        />{" "}
                        Play
                      </>
                    )}
                  </MusicPlayPauseButton>
                  <MusicPlayPauseButton onClick={shufflePlaylist}>
                    <BsShuffle /> Shuffle
                  </MusicPlayPauseButton>
                  {(currentUser?.uid === playlist?.curator?.curatorId ||
                    playlist?.collaborators?.includes(currentUser?.uid)) && (
                    <MusicPlayPauseButton
                    transparent={true}
                      onClick={() => {
                        const params = new URLSearchParams({ edit: true });
                        navigate(`${location.pathname}?${params.toString()}`, {
                          replace: true,
                        });
                      }}
                    >
                      Edit
                    </MusicPlayPauseButton>
                  )}
                </>
              )}
              {!currentUser?.uid && <MusicPlayPauseButton onClick={() => {
                    togglePreview(playlist?.songs)
                       
                   /*  if(song?.isDownloadable){
                    toggleModal()
                    modalContent(<OpenMusicStorePlayer type={CONTENT_TYPES.SONG} content={song}/>)
                    } */
                    }}>
                    <>
                        <FaPlay style={{ position: "relative", left: "1%" }} />{" "}
                        Preview
                      </>
                    </MusicPlayPauseButton>}
              {currentUser?.uid && !isEditing && (
                <OptionButton
                onClick={() =>
                  {openModal()
                  modalContent(<MusicOptionsModal content={playlist} type={ CONTENT_TYPES.PLAYLIST}/>)
                }
              }
                >
                  <OptionMenuButton />
                </OptionButton>
              )}
            </MusicOptionsContainer>
          </MusicHeadingTextWrapper>
        </MusicHeadingWrapper>
      </MusicHeadingContainer>

      {!isEditing && (
        <>
         
            {(currentUser?.uid === playlist?.curator?.curatorId ||
                    playlist?.collaborators?.includes(currentUser?.uid)) && <NavContainer>
              <MusicNav>
                <MusicNavLink
                  onClick={() => setTab("music")}
                  active={currentTab === "music"}
                >
                  Music
                </MusicNavLink>
                <MusicNavLink
                  onClick={() => setTab("stats")}
                  active={currentTab === "stats"}
                >
                  Statistics
                </MusicNavLink>
                </MusicNav>
                </NavContainer>}
          {currentTab === 'music' && <><MusicDetailsContainer>
            <MusicInfoWrapper>
              <MusicAboutWrapper>{playlist?.about}</MusicAboutWrapper>
              {playlist?.collaborators?.length > 0 && (
                <CustomButton
                  onClick={() => {
                    openModal();
                    modalContent(
                      <PlaylistCollaboratorsViewer playlist={playlist}/>
                    );
                  }}
                >
                  View Collaborators
                </CustomButton>
              )}
              {playlist?.songs?.length > 0 &&  (
                <ContentWrapper heading={"Playlist"}>
                  {isQueueLoaded && <SongList
                    type={CONTENT_TYPES.SONG}
                    onButtonClick={currentUser?.uid ? playSong : togglePreviewSong}
                    content={
                      playlist?.songs &&
                      playlist?.songs?.map((p) => {
                        return {
                          ...p,
                          playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
                            id: playlist?.playlistId,
                          },
                        };
                      })
                    }
                  />}
                  {!isQueueLoaded && <CollectionSongList content={
                      playlist?.songs?.length > 0 &&
                      playlist?.songs?.map((p) => {
                        return {
                          ...p,
                          playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
                            id: playlist?.playlistId,
                          },
                        };
                      })
                    }/>}
                </ContentWrapper>
              )}
            </MusicInfoWrapper>
          </MusicDetailsContainer>

          <MusicContentWrapper>
            <ContentWrapper heading={`Comments`}>
              <CommentsSection
                type={CONTENT_TYPES.PLAYLIST}
                id={playlist?.playlistId}
                contentName={playlist?.playlistName}
              />
            </ContentWrapper>
            {isLoaded && (playlists?.length > 0 && playlists?.playlists.filter(p => p?.playlistId !== playlist?.playlistId)?.length > 0)&& <ContentWrapper heading={`Playlists Curated by ${curatorName && curatorName !== playlist?.curator?.curatorId
                  ? curatorName
                  : `${playlist?.curator?.curatorId.substring(
                      0,
                      8
                    )}...${playlist?.curator?.curatorId.substring(
                      playlist?.curator?.curatorId.length - 5,
                      playlist?.curator?.curatorId.length
                    )}`}`} onClick={() => playlists?.length > 10 && 
                      navigate(`/u/${playlist?.curator?.curatorId}/playlists`)
                    } subheading={playlists?.length > 10 && 'View All'}>
                      <Slider id={'user Playlists'} type={SLIDER_TYPES.PLAYLIST} slides={playlists?.playlists.filter(p => p?.playlistId !== playlist?.playlistId)}/>
            </ContentWrapper>}
            {!isLoaded && <ContentWrapper heading={'User Playlists'}>
              <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
              </ContentWrapper>}
            {isCPLoaded && collabPlaylists?.length > 0 && <ContentWrapper heading={`${curatorName && curatorName !== playlist?.curator?.curatorId
                  ? curatorName
                  : `${playlist?.curator?.curatorId.substring(
                      0,
                      8
                    )}...${playlist?.curator?.curatorId.substring(
                      playlist?.curator?.curatorId.length - 5,
                      playlist?.curator?.curatorId.length
                    )}`} Collaborative Playlists`} onClick={() => 
                      collabPlaylists?.length > 10 && navigate(`/u/${playlist?.curator?.curatorId}/collabPlaylists`)
                    } subheading={collabPlaylists?.length > 10 && 'View All'}>
                      <Slider id={'collabPlaylists'} type={SLIDER_TYPES.PLAYLIST} slides={collabPlaylists?.playlists.filter(p => p?.playlistId !== playlist?.playlistId)}/>
            </ContentWrapper>}
            {!isCPLoaded && <ContentWrapper heading={'Collaborative Playlists'}>
              <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
              </ContentWrapper>}
          </MusicContentWrapper></>}
          {currentTab === 'stats' && <PlaylistStats playlistId={id}/>}
        </>
      )}
      {isEditing && <MusicEditPage type="playlist" content={playlist} />}
    </MusicPageWrapper>
  );
}
