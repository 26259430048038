import { useEffect, useReducer, useState } from "react";
import { useAlert } from "../components/Alert/AlertContext";
import axiosInstance from "../components/Authorization/client";
import { useAuth } from "../contexts/AuthContext";
import { getObjectLength, getUniqueListBy, strToHex } from "../utils";
import { ALERT_TYPES } from "../utils/Enum";

const axios = axiosInstance;

function useMusic() {
  const [music, setMusic] = useState({});
  const { addAlert } = useAlert();
  const { currentUser } = useAuth();

  useEffect(() => {
    return () => {};
  }, []);

  //get Song information from database
  const getSong = async (id) => {
    //client.open('api')
    return await axios
      .get(`/api/${id}/getSong`)
      .then(async (song) => {
        if (song?.data && !song?.data.error) {
          return song.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const getSongs = async (songs) => {
    let s = [];
    let songRequests = [];
    for (let i = 0; i < songs.length; i++) {
      songRequests.push(axios.get(`/api/${songs[i]}/getSong`));
    }
    return await Promise.all(songRequests)
      .then((req) => {
        s = req
          .filter((song) => song.data && !song.data?.error)
          .map((s) => {
            return s.data;
          });
        return s;
      })
      .catch((err) => {
        return false;
      });
  };

  const getSongTitle = (id) => {
    axios.get(`/songs/${id}/getSongTitle`).then((title) => {
      if (title.data && !title.data.error) {
        return title.data;
      } else {
        return false;
      }
    });
  };

  const likeSong = (id) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Adding to likes", secs: 10 });

    return axios
      .get(`/api/song/${id}/${currentUser?.uid}/like`)
      .then((like) => {
        if (like.data && !like.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Song added to likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const unlikeSong = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from likes",
      secs: 10,
    });

    return axios
      .get(`/api/song/${id}/${currentUser?.uid}/unlike`)
      .then((like) => {
        if (like.data && !like?.data?.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Song removed from likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getSongLike = (id) => {
    return axios
      .get(`/api/song/${id}/${currentUser?.uid}/getLike`)
      .then((like) => {
        return like.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  //Add song to watchlist
  const watchSong = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Adding to watchlist",
      secs: 10,
    });

    return axios
      .get(`/api/song/${id}/${currentUser?.uid}/watch`)
      .then((watchlist) => {
        if (watchlist.data && !watchlist.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Song added to watchlist",
          });
          return watchlist.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Watchlist Error",
          message: watchlist.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Watchlist Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  //Remove song from watchlist
  const unwatchSong = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from watchlist",
      secs: 10,
    });
    return axios
      .get(`/api/song/${id}/${currentUser?.uid}/unwatch`)
      .then((watchlist) => {
        if (watchlist.data && !watchlist.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Song removed from watchlist",
          });
          return watchlist.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Watchlist Error",
          message: watchlist.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Watchlist Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getSongWatch = (id) => {
    return axios
      .get(`/api/song/${id}/${currentUser?.uid}getWatch`)
      .then((watch) => {
        return watch.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  //get Playlist information from database
  const getPlaylist = async (id) => {
    let playlistInfo = await axios
      .get(`/api/${id}/getPlaylist`)
      .then((playlist) => {
        if (!playlist.data.error || !playlist.data) {
          return playlist.data;
        }
        return false;
      })
      .catch((err) => {
        return err;
      });

    if (playlistInfo !== false) {
      let songs = [];
      if (playlistInfo?.songs?.length <= 4) {
        return playlistInfo;
      } else {
        for (let i = 0; i <= playlistInfo?.songs?.length - 1; i++) {
          let song = await getSong(playlistInfo.songs[i]);
          if (song !== false) {
            songs.push(song);
          }
        }
        playlistInfo = {
          ...playlistInfo,
          songs: songs,
        };
        return playlistInfo;
      }
    } else {
      return false;
    }
  };

  const updateMusicPlay = async (userId,type, id, playId, playType, playingFrom ) => {
    let stats = strToHex(`${userId}.${type}.${id}.${playId}.${playType}.${playingFrom?.playingFrom}`)
     let location = {} /*= navigator.geolocation.getCurrentPosition((position) => {
      return {
        ...position?.GeolocationPosition?.coords,
        timestamp: position?.GeolocationPosition?.timestamp
      }
    }, (err) => {
      return undefined
    }) */
    return axios.post(`/api/stats/play/${stats}`, {location, playingFrom}).then((play) => {
      if(play.data && !play.data.error){
        //console.log(play.data.id)
        return play.data.id
      }
      return false
    }).catch(err => {
      return false
    })
  
  }

  const likePlaylist = (id) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Adding to likes", secs: 10 });

    return axios
      .get(`/api/playlist/${id}/${currentUser?.uid}/like`)
      .then((like) => {
        if (like.data && !like.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Playlist added to likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const unlikePlaylist = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from likes",
      secs: 10,
    });

    return axios
      .get(`/api/playlist/${id}/${currentUser?.uid}/unlike`)
      .then((like) => {
        if (like.data && !like?.data?.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Playlist removed from likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getPlaylistLike = (id) => {
    return axios
      .get(`/api/playlist/${id}/${currentUser?.uid}/getLike`)
      .then((like) => {
        return like.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  // get album information from database
  const getAlbum = async (id) => {
    let albumInfo = await axios
      .get(`/api/${id}/getAlbum`)
      .then(async (response) => {
        if (response.data && !response.data.error) {
          return response.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
    let songs = [];

    if (albumInfo?.songs?.length > 5) {
      songs = await getSongs(albumInfo.songs);

      albumInfo = {
        ...albumInfo,
        songs: songs,
      };
      return albumInfo;
    } else {
      return albumInfo;
    }
  };

  const getAlbums = async (albums) => {
    let a = [];
    for (let i = 0; i < albums.length; i++) {
      let album = await getAlbum(albums[i]);
      if (album) {
        a.push(album);
      }
    }
    return a;
  };

  const likeAlbum = (id) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Adding to likes", secs: 10 });
    return axios
      .get(`/api/album/${id}/${currentUser?.uid}/like`)
      .then((like) => {
        if (like.data && !like.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Album added to likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const unlikeAlbum = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from likes",
      secs: 10,
    });

    return axios
      .get(`/api/album/${id}/${currentUser?.uid}/unlike`)
      .then((like) => {
        if (like.data && !like?.data?.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Album removed from likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getAlbumLike = (id) => {
    return axios
      .get(`/api/album/${id}/${currentUser?.uid}/getLike`)
      .then((like) => {
        return like.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  //get Episode information from database
  const getEpisode = async (id) => {
    return await axios
      .get(`/api/${id}/getEpisode`)
      .then(async (response) => {
        if (response?.data && !response?.data?.error) {
          return response;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const getEpisodes = async (episodes) => {
    let e = [];
    for (let i = 0; i < episodes.length; i++) {
      let episode = await getEpisode(episodes[i]);
      if (episode) {
        e.push(episode);
      }
    }
    return e;
  };

  const likeEpisode = (id) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Adding to likes", secs: 10 });

    return axios
      .get(`/api/episode/${id}/${currentUser?.uid}/like`)
      .then((like) => {
        if (like.data && !like.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Episode added to likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const unlikeEpisode = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from likes",
      secs: 10,
    });

    return axios
      .get(`/api/episode/${id}/${currentUser?.uid}/unlike`)
      .then((like) => {
        if (like.data && !like?.data?.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Episode removed from likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  //Add episode to watchlist
  const watchEpisode = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Adding to watchlist",
      secs: 10,
    });

    return axios
      .get(`/api/episode/${id}/${currentUser?.uid}/watch`)
      .then((watchlist) => {
        if (watchlist.data && !watchlist.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Song added to watchlist",
          });
          return watchlist.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Watchlist Error",
          message: watchlist.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Watchlist Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  //Remove episode from watchlist
  const unwatchEpisode = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from watchlist",
      secs: 10,
    });
    return axios
      .get(`/api/episode/${id}/${currentUser?.uid}/unwatch`)
      .then((watchlist) => {
        if (watchlist.data && !watchlist.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Song removed from watchlist",
          });
          return watchlist.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Watchlist Error",
          message: watchlist.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Watchlist Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getEpisodeWatch = (id) => {
    return axios
      .get(`/api/episode/${id}/${currentUser?.uid}getWatch`)
      .then((watch) => {
        return watch.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getEpisodeLike = (id) => {
    return axios
      .get(`/api/episode/${id}/${currentUser?.uid}getLike`)
      .then((like) => {
        return like.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const generateISRCCode = () => {
    return axios
      .get("/api/isrc/generate")
      .then((code) => {
        if (code.data.error || code.data === false) {
          return false;
        }
        return code.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getGenre = async (id) => {
    return axios
      .get(`/api/${id}/getGenre`)
      .then((genres) => {
        if (genres?.data && !genres?.data?.error) {
          return genres.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSubgenre = async (id) => {
    return axios
      .get(`/api/${id}/getSubgenre`)
      .then((genres) => {
        if (genres?.data && !genres?.data?.error) {
          return genres.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getPodcastGenre = async (id) => {
    return axios
      .get(`/api/${id}/getPodcastGenre`)
      .then((genres) => {
        if (genres?.data && !genres?.data?.error) {
          return genres.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  // get All genres and subgenres from database
  const getAllGenres = async (id) => {
    return axios
      .get(`/api/genres/getAll`)
      .then((genres) => {
        if (genres.data && !genres.data.error) {
          return genres.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  // get All podcast genres from database
  const getPodcastGenres = async (id) => {
    return axios
      .get(`/api/genres/podcast/getAll`)
      .then((genres) => {
        if (genres?.data && genres?.data?.error) {
          return genres.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const searchGenres = (genre) => {
    return axios
      .post(`/api/genres/searchGenres`, {
        genre: genre,
      })
      .then((genres) => {
        return genres.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const searchPodcastGenres = (genre) => {
    return axios
      .post(`/api/genres/searchPodcastGenres`, {
        genre: genre,
      })
      .then((genres) => {
        return genres.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const createMp3File = async ({ uid, token, ref, url }) => {
    return axios
      .post(`/api/${uid}/createMp3File`, {
        token: token,
        url: url,
        songRef: ref,
      })
      .then((info) => {
        if (info.data && !info?.data?.error) {
          console.log(info.data);
          return info.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const getLowerVideoResolutions = async ({ uid, ref, url }) => {
    await axiosInstance
      .post(`/api/video/${uid}/artists/getLowerVideoResolutions`, {
        ref: ref,
        url: url,
      })
      .then((resp) => {
        if (resp.data?.videoResolutions?.length > 0) {
          console.log(resp.data?.videoResolutions);
        }
        return resp.data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const newSongId = () => {
    return axios
      .get("/api/song/newId")
      .then((song) => {
        return song.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const uploadAlbum = async (albumData, type) => {
    switch (type) {
      case "draft":
        //console.log(albumData)
        return axios
          .post(`/api/album/saveDraft`, {
            ...albumData,
          })
          .then((album) => {
            return album.data?.message ? album.data : false;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      case "publish":
        return await axios
          .post(`/api/album/upload`, {
            albumInfo: albumData,
          })
          .then((album) => {
            return album.data;
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      default:
        return false;
    }
  };

  const updateAlbum = (userId, albumId, albumInfo) => {
    return axios
      .post(`/api/albums/${userId}/${albumId}/updateAlbum`, {
        albumInfo: albumInfo,
      })
      .then((data) => {
        if (data.data && !data.data.error) {
          //console.log(data.data)
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const uploadSong = (songData, type) => {
    switch (type) {
      case "draft":
        return axios
          .post(`/api/song/saveDraft`, {
            ...songData,
          })
          .then((song) => {
            return song.data?.message ? song.data : false;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      case "publish":
        return axios
          .post(`/api/song/upload`, {
            ...songData,
          })
          .then((song) => {
            return song.data;
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      default:
        return false;
    }
  };

  const updateSong = (userId, songId, songInfo) => {
    return axios
      .post(`/api/songs/${userId}/${songId}/updateSong`, { songInfo: songInfo })
      .then((data) => {
        if (data.data && !data.data.error) {
          //console.log(data.data)
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const uploadEpisode = (episodeData, type) => {
    switch (type) {
      case "draft":
        return axios
          .post(`/api/episode/saveDraft`, {
            ...episodeData,
          })
          .then((episode) => {
            return episode.data?.message ? episode.data : false;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });

      case "publish":
        return axios
          .post(`/api/episode/upload`, {
            ...episodeData,
          })
          .then((episode) => {
            return episode.data;
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      default:
        return false;
    }
  };

  const updateEpisode = (userId, episodeId, episodeInfo) => {
    return axios
      .post(`/api/episodes/${userId}/${episodeId}/updateEpisode`, {
        episodeInfo: episodeInfo,
      })
      .then((data) => {
        if (data.data && !data.data.error) {
          //console.log(data.data)
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getDraft = async (id, type) => {
    return await axios
      .get(`/api/drafts/${type}/${id}/getDraft`)
      .then((draft) => {
        if (draft.data.error || !draft.data) {
          return false;
        }
        return draft.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const getDraftsByUid = async (id) => {
    return await axios
      .get(`/api/drafts/${id}/getDrafts`)
      .then((draft) => {
        if (draft.data.error || draft.data === false) {
          return false;
        }
        return draft.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const getDraftCollections = async (id) => {
    return await axios
      .get(`/api/${id}/getDraftCollections`)
      .then((drafts) => {
        //console.log(drafts)
        if (drafts?.data?.error || drafts.data === false) {
          return false;
        }
        return drafts?.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const deleteDraft = async (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      message: "Deleting Draft",
      secs: 10,
      title: "Draft Delete",
    });

    return await axios
      .get(`/api/drafts/${id}/deleteDraft`)
      .then((draft) => {
        //console.log('deleting: ', id)
        if (draft.data.error || draft.data === false) {
          addAlert({
            type: ALERT_TYPES.DANGER,
            title: "Draft Delete Error",
            message: "Draft Was Not Deleted",
          });
          return false;
        }
        addAlert({ type: ALERT_TYPES.SUCCESS, message: "Draft Deleted" });
        return draft.data;
      })
      .catch((err) => {
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Draft Delete Error",
          message: "Something went wrong",
        });

        return false;
      });
  };

  const deleteAllDrafts = async (userId) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      message: "Deleting All Drafts",
      secs: 10,
    });

    return await axios
      .get(`/api/drafts/${userId}/deleteAllDrafts`)
      .then((draft) => {
        //console.log('deleting: ', id)
        if (draft.data.error || draft.data === false) {
          addAlert({
            type: ALERT_TYPES.DANGER,
            title: "Draft Delete Error",
            message: "Drafts Not Deleted",
          });
          return false;
        }
        addAlert({ type: ALERT_TYPES.SUCCESS, message: "Drafts Deleted" });
        return draft.data;
      })
      .catch((err) => {
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Draft Delete Error",
          message: "Something went wrong",
        });

        return false;
      });
  };

  const deleteAllCollectionDrafts = async (userId) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      message: "Deleting All Drafts",
      secs: 10,
    });

    return await axios
      .get(`/api/collections/${userId}/deleteAllDrafts`)
      .then((draft) => {
        //console.log('deleting: ', id)
        if (draft.data.error || draft.data === false) {
          addAlert({
            type: ALERT_TYPES.DANGER,
            title: "Draft Delete Error",
            message: "Drafts Not Deleted",
          });
          return false;
        }
        addAlert({ type: ALERT_TYPES.SUCCESS, message: "Drafts Deleted" });
        return draft.data;
      })
      .catch((err) => {
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Draft Delete Error",
          message: "Something went wrong",
        });

        return false;
      });
  };

  const bulkMusicSearch = async (name) => {
    let [songs, albums, playlists] = await Promise.all([
      searchSongs(name),
      searchAlbums(name),
      searchPlaylists(name),
    ]);
    return { songs, albums, playlists };
  };

  const searchPlaylists = async (name) => {
    let playlists = await Promise.all([
      searchPlaylistsByName(name),
      searchPlaylistsBySong(name),
    ])
      .then((data) => {
        return [
          ...data.filter((playlist) => {
            if (playlist !== false) {
              return [...playlist.map((p) => p)];
            }
          }),
        ];
      })
      .catch((err) => {
        console.error(err);
        return [];
      });

    let p = [];
    playlists.map((playlist) => {
      p.push(...playlist);
    });
    return p;
  };

  const searchAlbums = async (name) => {
    return await Promise.all([
      searchAlbumsByName(name),
      searchAlbumsByArtistName(name),
      searchAlbumsBySong(name),
    ])
      .then((data) => {
        let albums = [];
        if (data[0]) {
          albums.push(...data[0]);
        }
        if (data[1]) {
          albums.push(...data[1]);
        }
        if (data[2]) {
          albums.push(...data[2]);
        }
        if (albums?.length > 0) {
          return albums;
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  const searchSongs = async (name) => {
    let songs = await Promise.all([
      searchSongsByName(name),
      searchSongsByArtist(name),
      searchSongsByFArtist(name),
    ])
      .then((data) => {
        return [
          ...data.filter((song) => {
            if (song !== false) {
              return song.map((s) => s);
            }
          }),
        ];
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
    let s = [];
    songs.map((song) => {
      s.push(...song);
      return;
    });
    return s;
  };

  const searchSongsByName = async (name) => {
    const controller = new AbortController();
    return await axios
      .post("/api/songs/searchByName", {
        songName: name,
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchMintableSongsByNameAndUid = async (uid, name) => {
    const controller = new AbortController();
    return await axios
      .get(`/api/songs/${uid}/${name}/searchMintableSongs`, {
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchMintableAlbumsByNameAndUid = async (uid, name) => {
    const controller = new AbortController();
    return await axios
      .get(`/api/albums/${uid}/${name}/searchMintableAlbums`, {
        signal: controller.signal,
      })
      .then((albums) => {
        //console.log(albums.data)
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchSongsByNameAndUid = async (uid, name) => {
    const controller = new AbortController();

    return await axios
      .get(`/api/songs/${uid}/${name}/searchSongs`, {
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchSongsByArtist = async (name) => {
    const controller = new AbortController();

    return await axios
      .post(`/api/songs/searchByArtist`, {
        artistName: name,
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchSongsByFArtist = async (name) => {
    const controller = new AbortController();

    return await axios
      .post("/api/songs/searchByFArtist", {
        artistName: name,
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchSongsbyGenre = async (genreId) => {
    const controller = new AbortController();

    return axios
      .post(`api/songs/searchByGenre`, {
        genre: genreId,
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchSongsbySubgenre = async (genreId) => {
    const controller = new AbortController();

    return axios
      .post(`api/songs/searchBySubgenre`, {
        genre: genreId,
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchAlbumsByName = async (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/albums/searchByName", {
        albumName: name,
        signal: controller.signal,
      })
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        //console.log(err)
        return false;
      });
  };

  const searchEpisodesByName = async (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/episodes/searchByName", {
        episodeName: name,
        signal: controller.signal,
      })
      .then((episodes) => {
        if (episodes.data.error || episodes.data === false) {
          return false;
        }
        return episodes.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchAlbumsByArtistName = (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/albums/searchByArtistName", {
        artistName: name,
        signal: controller.signal,
      })
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchAlbumsByArtistId = (id, page) => {
    const controller = new AbortController();

    return axios
      .get(`/api/${id}/getArtistAlbums/${page}`, { signal: controller.signal })
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchAlbumsBySong = (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/albums/searchBySong", {
        songName: name,
        signal: controller.signal,
      })
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchEpisodesByPodcasterName = (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/episodes/searchByPodcasterName", {
        podcasterName: name,
        signal: controller.signal,
      })
      .then((episodes) => {
        if (episodes.data.error || episodes.data === false) {
          return false;
        }
        return episodes.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchPlaylistsByName = (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/playlists/searchByName", {
        playlistName: name,
        signal: controller.signal,
      })
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const searchPlaylistsBySong = (name) => {
    const controller = new AbortController();

    return axios
      .post("/api/playlists/searchBySong", {
        songName: name,
        signal: controller.signal,
      })
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const digitalPurchase = (purchaseData) => {
    return axios
      .post(`/api/purchases/digitalDownload`, { ...purchaseData })
      .then((purchase) => {
        if (purchase.data.error || purchase.data === false) {
          return false;
        }
        return purchase.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const nfTokenPurchase = async (purchaseData) => {
    return await axios
      .post(`/api/purchases/nftPurchase`, { ...purchaseData })
      .then((purchase) => {
        if (purchase.data.error || !purchase) {
          return false;
        }
        return purchase.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const mintNft = async (collectionId, userToken) => {
    return await axios
      .post(`/api/${collectionId}/${userToken}/mintCollection`)
      .then((collection) => {
        if (collection.data.error || collection.data === false) {
          return false;
        }
        return collection.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const deletePlaylist = (id, uid) => {
    return axios
      .get(`/api/${id}/${uid}/deletePlaylist`)
      .then((playlist) => {
        if (playlist.data.error || playlist.data === false) {
          return false;
        }
        return playlist.data.message;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getLatestReleases = async () => {
    return await Promise.all([getLatestAlbums(0), getLatestSongs(0)])
      .then((releases) => {
        let data = [];
        if (releases[0] !== false) {
          data.push(...releases[0]?.albums);
        }
        if (releases[1] !== false) {
          data.push(...releases[1]?.songs);
        }
        return data;
      })
      .catch((err) => {
        return [];
      });
  };

  const getLatestEpisodes = async (page) => {
    return await axios
      .get(`/api/episodes/getLatest/${page}`)
      .then((episodes) => {
        if (episodes?.data?.error || episodes?.data === false) {
          return false;
        }
        return episodes.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getLatestAlbums = async (page) => {
    return await axios
      .get(`/api/albums/getLatest/${page}`)
      .then((albums) => {
        if (albums?.data?.error || albums?.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getLatestSongs = (page) => {
    return axios
      .get(`/api/songs/getLatest/${page}`)
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getTopReleases = async () => {
    return await Promise.all([getTopAlbums(0), getTopSongs(0)])
      .then((releases) => {
        let data = [];
        if (releases[0] !== false) {
          data.push(...releases[0]?.albums);
        }
        if (releases[1] !== false) {
          data.push(...releases[1]?.songs);
        }
        return data;
      })
      .catch((err) => {
        return [];
      });
  };

  const getTopSongs = (page) => {
    return axios
      .get(`/api/songs/getTopSongs/${page}`)
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getTopAlbums = async (page) => {
    return await axios
      .get(`/api/albums/getTopAlbums/${page}`)
      .then((albums) => {
        if (albums?.data?.error || albums?.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getDownloadableAlbums = (page) => {
    return axios
      .get(`/api/albums/getDownloadable/${page}`)
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getDownloadableAlbumsByName = async (name) => {
    let albumsData = await axios
      .get(`/api/albums/${name}/getDownloadableAlbumsByName`)
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
    if (albumsData?.length <= 4) {
      return albumsData;
    } else {
      let albums = [];
      for (let i = 0; i <= albumsData.length - 1; i++) {
        let album = await getAlbum(albumsData[i]);
        albums.push(album);
      }
      return albums;
    }
  };

  const getSubscriptionAlbumsByName = (name) => {
    return axios
      .get(`/api/albums/${name}/getSubscriptionAlbumsByName`)
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getDownloadableSongs = (page) => {
    return axios
      .get(`/api/songs/getDownloadable/${page}`)
      .then((songs) => {
        if (songs.data && !songs?.data?.error) {
          return songs.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getDownloadableSongsByName = async (name) => {
    const controller = new AbortController();

    let songsData = await axios
      .get(`/api/songs/${name}/getDownloadableSongsByName`, {
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
    if (songsData === false) {
      return [];
    }
    if (songsData?.length <= 4) {
      return songsData;
    } else {
      let songs = [];
      for (let i = 0; i <= songsData.length - 1; i++) {
        let song = await getSong(songsData[i]);
        songs.push(song);
      }
      return songs;
    }
  };

  const getSubscriptionAlbums = () => {
    const controller = new AbortController();

    return axios
      .get("/api/albums/getSubscriptionAlbums", { signal: controller.signal })
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSubscriptionSongs = async () => {
    const controller = new AbortController();

    return await axios
      .get("/api/songs/getSubscriptionSongs")
      .then((songs) => {
        if (songs.data && !songs?.data?.error) {
          return songs.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSubscriptionSongsByName = (name) => {
    const controller = new AbortController();

    return axios
      .get(`/api/songs/${name}/getSubscriptionSongsByName`, {
        signal: controller.signal,
      })
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokenCollection = async (collectionId) => {
    let collectionInfo = await axios
      .get(`/api/${collectionId}/getCollection`)
      .then((collection) => {
        if (collection.data.error || collection.data === false) {
          return false;
        }
        return collection.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
    if (collectionInfo !== false) {
      let albums = [];
      let songs = [];

      if (collectionInfo?.albums && collectionInfo?.albums?.length > 0) {
        albums = await getAlbums(collectionInfo?.albums);
      }
      if (collectionInfo?.songs && collectionInfo?.songs?.length > 0) {
        songs = await getSongs(collectionInfo.songs);
      }

      collectionInfo = {
        ...collectionInfo,
        albums: albums?.length > 0 ? albums : undefined,
        songs: songs?.length > 0 ? songs : undefined,
      };
      return collectionInfo;
    } else {
      return false;
    }
  };

  const likeNFTokenCollection = (id) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Adding to likes", secs: 10 });

    return axios
      .get(`/api/collection/${id}/${currentUser?.uid}/like`)
      .then((like) => {
        if (like.data && !like.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Collection added to likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const unlikeNFTokenCollection = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from likes",
      secs: 10,
    });

    return axios
      .get(`/api/collection/${id}/${currentUser?.uid}/unlike`)
      .then((like) => {
        if (like.data && !like?.data?.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Collection removed from likes",
          });
          return like.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Like Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getNFTokenCollectionLike = (id) => {
    return axios
      .get(`/api/collection/${id}/${currentUser?.uid}/getLike`)
      .then((like) => {
        return like.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  //Add song to watchlist
  const watchNFTokenCollection = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Adding to watchlist",
      secs: 10,
    });

    return axios
      .get(`/api/collection/${id}/${currentUser?.uid}/watch`)
      .then((watchlist) => {
        if (watchlist.data && !watchlist.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Collection added to watchlist",
          });
          return watchlist.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Watchlist Error",
          message: watchlist.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Watchlist Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  //Remove song from watchlist
  const unwatchNFTokenCollection = (id) => {
    addAlert({
      type: ALERT_TYPES.INFO,
      title: "Removing from watchlist",
      secs: 10,
    });
    return axios
      .get(`/api/collection/${id}/${currentUser?.uid}/unwatch`)
      .then((watchlist) => {
        if (watchlist.data && !watchlist.data.error) {
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: "Collection removed from watchlist",
          });
          return watchlist.data;
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Watchlist Error",
          message: watchlist.data.error,
        });
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Watchlist Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return err;
      });
  };

  const getNFTokenCollections = async (page) => {
    return await axios
      .get(`/api/collections/${page}/getCollections`)
      .then((collections) => {
        if (collections.data && !collections?.data?.error) {
          return collections.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokenCollectionOwners = async (issuer, taxon) => {
    return await axios
      .get(`/api/collection/${issuer}/${taxon}/getNFTokenCollectionOwners`)
      .then((result) => {
        if (result.data && !result.data?.error) {
          return result.data.owners;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokenCollectionsByName = async (name) => {
    const controller = new AbortController();

    return axios
      .get(`/api/collections/${name}/getCollectionsByName`, {
        signal: controller.signal,
      })
      .then((collections) => {
        if (collections.data.error || collections.data === false) {
          return false;
        }
        return collections.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSimilarCollections = async (type, page) => {
    return axios
      .get(`/api/collections/${type}/getSimilarCollections/${page}`)
      .then((collections) => {
        if (collections.data.error || collections.data === false) {
          return false;
        }
        return collections.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokenCollectionsByAlbum = async (id, page) => {
    return axios
      .get(`/api/collection/${id}/getCollectionsByAlbumId/${page}`)
      .then((collections) => {
        if (collections.data.error || collections.data === false) {
          return false;
        }
        return collections.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokenCollectionsBySong = async (id, page) => {
    return axios
      .get(`/api/collection/${id}/getCollectionsBySongId/${page}`)
      .then((collections) => {
        if (collections.data.error || collections.data === false) {
          return false;
        }
        return collections.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const addPlaylist = async ({
    uid,
    name,
    songs,
    playlistArt,
    color,
    about,
    collaborators,
  }) => {
    return await axios
      .post(`/api/playlists/addPlaylist/${uid}`, {
        name: name,
        songs: songs,
        playlistArt: playlistArt,
        color: color,
        about: about,
        collaborators: collaborators,
      })
      .then((playlist) => {
        if (playlist.data.error || playlist.data === false) {
          return false;
        }
        return playlist.data.playlistId;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getTrendingPlaylists = (page) => {
    return axios
      .get(`/api/playlists/${page}/getTrending`)
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNewestPlaylists = (page) => {
    return axios
      .get(`/api/playlists/${page}/getNewest`)
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getPlaylistsContainingSong = (songId, page) => {
    return axios
      .get(`/api/playlists/${songId}/${page}/getPlaylistsContainingSong`)
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSongsByUid = async (uid) => {
    return await axios
      .get(`/api/songs/${uid}/getSongUploads`)
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getAlbumsByUid = async (uid) => {
    return await axios
      .get(`/api/albums/${uid}/getAlbumUploads`)
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSonarMusePlaylists = async () => {
    return await axios
      .get("/api/playlists/getSonarPlaylists")
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getLatestSonarMusePlaylists = async () => {
    return await axios
      .get("/api/playlists/getLatestSonarPlaylist")
      .then((playlists) => {
        if (playlists.data.error || playlists.data === false) {
          return false;
        }
        return playlists.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSimilarSongs = async (song, page) => {
    return await axios
      .get(`/api/songs/${song}/getSimilarSongs/${page}`)
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSimilarSongsByGenre = async (genreId, page) => {
    return await axios
      .get(`/api/songs/${genreId}/getSimilarSongsByGenre/${page}`)
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSimilarSongsBySubgenre = async (subgenreId, page) => {
    return await axios
      .get(`/api/songs/${subgenreId}/getSimilarSongsBySubgenre/${page}`)
      .then((songs) => {
        if (songs.data.error || songs.data === false) {
          return false;
        }
        return songs.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSimilarSongsBySubgenres = async (subgenres, page) => {
    let similarSongs = [];
    for (let i = 0; i < subgenres.length; i++) {
      let songs = await getSimilarSongsBySubgenre(subgenres[i].genreId, page);
      if (songs) {
        similarSongs.push(...songs);
      }
    }
    return getUniqueListBy(similarSongs, "songId");
  };

  const getSimilarAlbums = async (album, page) => {
    return await axios
      .get(`/api/albums/${album}/getSimilarAlbumsByAlbumId/${page}`)
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSimilarAlbumsByGenre = async (genreId) => {
    return await axios
      .get(`/api/albums/${genreId}/getSimilarAlbumsByGenre`)
      .then((albums) => {
        if (albums.data.error || albums.data === false) {
          return false;
        }
        return albums.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const publishAlbum = () => {
    return;
  };

  const publishSong = () => {
    return;
  };

  const deleteAlbum = () => {
    return;
  };

  const deleteSong = (id, user) => {
    //console.log("deleting...");
    //console.log(user);
    return axios
      .post(`/api/${id}/deleteSong`, { uid: user })
      .then((song) => {
        //console.log("delete: ", song.data);
        return song.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const createNFTokenCollection = async (uid, body) => {
    return axios
      .post(`/api/${uid}/createNFTokenCollection`, {
        collectionId: body.collectionId,
        sender: body.sender,
        taxon: body.taxon,
        flag: body.flag,
        title: body.title,
        attributes: body.attributes,
        description: body.description,
        tokenSupply: body.tokenSupply,
        bgColor: body.bgColor,
        price: body.price,
        coverArt: body?.coverArt,
        currency: body.currency,
        unlimitedStreaming: body.unlimitedStreaming,
        transferFee: body.transferFee,
        mintType: body.mintType,
        music: body.music,
        discounts: body.discounts,
        isTokenGate: body?.isTokenGate,
        additionalFiles: body.additionalFiles,
        additionalFilesRef: body.additionalFilesRef,
        revShare: body.revShare,
        tokenUrl: body.tokenUrl,
        issuer: currentUser?.uid,
        userToken: localStorage.getItem("userToken"),
        instruction: body.instruction,
        memo: body.memo,
      })
      .then((collection) => {
        if (collection.data.error || collection.data === false) {
          return false;
        }
        return collection.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const saveNFTokenCollectionAsDraft = async (uid, body) => {
    return axios
      .post(`/api/${uid}/saveNFTCollectionAsDraft`, {
        collectionId: body.collectionId,
        taxon: body.taxon,
        flag: body.flag,
        title: body.title,
        isAttributes: body.isAttributes,
        isPreMint: body.isPreMint,
        mintDate: body.mintDate,
        attributes: body.attributes,
        description: body.description,
        isFixedSupply: body.isFixedSupply,
        mintQuantity: body.mintQuantity,
        color: body.color,
        price: body.price,
        coverArt: body?.coverArt,
        currency: body.currency,
        unlimitedStreaming: body.unlimitedStreaming,
        transferFee: body.transferFee,
        mintType: body.mintType,
        selectedMusic: body.selectedMusic,
        isDiscount: body.isDiscount,
        discountRedeemers: body.discountRedeemers,
        isTokenGate: body?.isTokenGate,
        revShare: body.revShare,
        revenue: body.revenue,
        tokenUrl: body.tokenUrl,
        issuer: currentUser?.uid,
      })
      .then((collection) => {
        if (collection.data.error || collection.data === false) {
          return false;
        }
        return collection.data;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  /*  const createNFTokenAirdrop = async (userId, collectionId, addresses, payload) => {
    return axios.post(`/api/${userId}/${collectionId}/createAirdropCollection`, {
      addresses: addresses,
      payload: payload
    })
    .then((collection) => {
      //console.log(collection.data)
      if (collection.data.error || collection.data === false) {
        return false;
      }
      return collection.data;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
  } */

  const validateCollectionId = async (taxonId, userId) => {
    return axios
      .get(`/api/collections/${taxonId}/${userId}/exists`)
      .then((collection) => {
        if (
          !collection.data.error &&
          collection.data === false &&
          !collection?.data?.collectionId
        ) {
          return { response: true };
        } else if (collection?.data?.collectionId) {
          return {
            response: false,
            collectionId: collection.data.collectionId,
          };
        } else {
          return { response: false, error: "something went wrong" };
        }
      })
      .catch((err) => {
        console.error(err);
        return { response: false, error: err };
      });
  };

  const getMusicComment = (id) => {
    return axios
      .get(`/api/comments/${id}/getComment`)
      .then((comment) => {
        if (!comment.data.error && comment.data !== false) {
          return comment?.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const getMusicComments = (type, id) => {
    return axios
      .get(`/api/comments/${type}/${id}/getComments`)
      .then((comments) => {
        if (!comments.data.error && comments.data !== false) {
          return comments.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        //console.error(err);
        return err;
      });
  };

  const updateSongDuration = async (id, duration) => {
    return axios
      .post(`/api/songs/${id}/updateDuration`, { duration: duration })
      .then((song) => {
        //console.log('Duration: ', song.data)
        if (!song.data.error) {
          return true;
        } else if (song.data === false) {
          return false;
        } else if (song.data) {
          return song.data;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const updateCollection = (userId, collectionId, collectionInfo) => {
    return axios
      .post(`/api/collections/${userId}/${collectionId}/updateCollection`, {
        collectionInfo: collectionInfo,
      })
      .then((data) => {
        if (data.data && !data.data.error) {
          //console.log(data.data)
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getMusicRoyaltyInfo = (royaltyId) => {
    return axios
      .get(`/api/royalties/${royaltyId}/getUserRoyaltyInfo`)
      .then((royalty) => {
        if (royalty.data && !royalty.data.error) {
          return royalty.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getMusicRoyalties = (next) => {
    return axios
      .get(`/api/royalties/${next}/getMusicRoyalties`)
      .then((royalties) => {
        if (royalties.data && !royalties.data.error) {
          return royalties.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getMusicRoyaltiesByType = async (type, next) => {
    /* return axios
      .get(`/api/royalties/${type}/${next}/getMusicRoyaltiesByType`)
      .then((royalties) => {
        if (royalties.data && !royalties.data.error) {
          return royalties.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      }) */;
      return false
  };

  const getRoyaltyTokenOwners = (currency) => {
    return axios
      .get(`/api/royalties/${currency}/getRoyaltyShareOwners`)
      .then((owners) => {
        if (owners.data && !owners.data.error) {
          return owners.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const downloadMusic = (uid, type, id, audioType) => {
    return axios
      .get(`/api/${uid}/${type}/${id}/${audioType}/downloadMusic`)
      .then((data) => {
        if (data.data && !data.data.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const requestMusicDownload = (uid, type, id, audioType) => {
    return axios
      .get(
        `/api/downloads/${uid}/${type}/${id}/${audioType}/requestMusicDownload`
      )
      .then((data) => {
        if (data.data && !data.data.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const downloadTokenGatedContent = (id) => {
    return axios
      .get(`/api/${id}/downloadTokenGatedFiles`)
      .then((data) => {
        if (data.data && !data.data.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getSwerlVouchers = async () => {
    return await axios
      .get(`/api/getSwerlVouchers`)
      .then((vouchers) => {
        if (vouchers.data && !vouchers?.data?.error) {
          return vouchers.data;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const toggleMintable = (uid, songId) => {
    return axios
      .get(`/api/${uid}/${songId}/setSongAsMintable`)
      .then((data) => {
        if (data.data && !data.data.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getTopDigitalPurchases = async () => {
    return await axios
      .get(`/api/getTopDigitalPurchases`)
      .then((data) => {
        if (data.data && !data.data.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .then(async (purchases) => {
        if (purchases) {
          let albums =
            getObjectLength(purchases?.albums) > 0
              ? await getAlbums(
                  Object.keys(purchases?.albums).filter((k, i) => i < 10)
                )
              : [];
          let songs =
            getObjectLength(purchases?.songs) > 0
              ? await getSongs(
                  Object.keys(purchases?.songs).filter((k, i) => i < 10)
                )
              : [];
          return {
            songs: songs,
            albums: albums,
          };
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getTopNFTokenPurchases = async () => {
    return await axios
      .get(`/api/getTopNFTokenPurchases`)
      .then((data) => {
        if (data.data && !data.data.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .then(async (purchases) => {
        if (purchases) {
          let collections = [];
          for (
            let i = 0;
            i < Object.keys(purchases).filter((k, index) => index < 10).length;
            i++
          ) {
            collections.push(
              await getNFTokenCollection(Object.keys(purchases)[i])
            );
          }
          return collections;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const validateRoyaltyTokenTicker = (ticker) => {
    return axios
      .get(`/api/royalties/${ticker}/validateRoyaltyTokenTicker`)
      .then((isValid) => {
        return isValid.data;
      })
      .catch((err) => {
        return false;
      });
  };

    //Statistics
    const getEpisodePlaysByDate = async (episodeId, filter) => {
      return axios.get(`/api/stats/episode/${episodeId}/${filter}/getPlays`)
      .then(plays => {
        if(!plays.data || plays.data.error){
            return false
        }
        return plays.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
  
    const getSongPlaysByDate = async (songId, filter) => {
      return axios.get(`/api/stats/song/${songId}/${filter}/getPlays`)
      .then(plays => {
        if(!plays.data || plays.data.error){
            return false
        }
        return plays.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
    const getCollectionPlaysByDate = async (collectionId, filter) => {
      return axios.get(`/api/stats/collection/${collectionId}/${filter}/getPlays`)
      .then(plays => {
        if(!plays.data || plays.data.error){
            return false
        }
        return plays.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
  
    const getAlbumPlaysByDate = async (albumId, filter) => {
      return axios.get(`/api/stats/album/${albumId}/${filter}/getPlays`)
      .then(plays => {
        if(!plays.data || plays.data.error){
            return false
        }
        return plays.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
  
    const getEpisodeLikesByDate = async (episodeId, filter) => {
     return axios.get(`/api/stats/episode/${episodeId}/${filter}/getLikes`)
      .then(likes => {
        if(!likes.data || likes.data.error){
            return false
        }
        return likes.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
    const getSongLikesByDate = async (songId, filter) => {
     return axios.get(`/api/stats/song/${songId}/${filter}/getLikes`)
      .then(likes => {
        if(!likes.data || likes.data.error){
            return false
        }
        return likes.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
  
    const getCollectionLikesByDate = async (collectionId, filter) => {
     return axios.get(`/api/stats/collection/${collectionId}/${filter}/getLikes`)
      .then(likes => {
        if(!likes.data || likes.data.error){
            return false
        }
        return likes.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
  
    const getAlbumLikesByDate = async (albumId, filter) => {
     return axios.get(`/api/stats/album/${albumId}/${filter}/getLikes`) 
      .then(likes => {
        if(!likes.data || likes.data.error){
            return false
        }
        return likes.data
    }).catch(err => {
        console.error(err)
        return false
    })
    }
  
    const getEpisodePlaylistAdditionsByDate = async (episodeId, filter, page) => {
      return axios.get(`/api/stats/episode/${episodeId}/${filter}/${page}/getPlaylistsAdditions`)
      .then(playlists => {
          if(!playlists.data || playlists.data.error){
              return false
          }
          return playlists.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
    const getSongPlaylistAdditionsByDate = async (songId, filter, page) => {
      return axios.get(`/api/stats/song/${songId}/${filter}/${page}/getPlaylistsAdditions`)
      .then(playlists => {
          if(!playlists.data || playlists.data.error){
              return false
          }
          return playlists.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getEpisodesContainingSongByDate = async (songId, filter, page) => {
      return axios.get(`/api/stats/song/${songId}/${filter}/${page}/getEpisodesAdditions`)
      .then(episodes => {
          if(!episodes.data || episodes.data.error){
              return false
          }
          return episodes.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getSongRoyaltyPurchasesByDate = async (songId, filter) => {
      return axios.get(`/api/stats/song/${songId}/${filter}/getRoyaltyPurchases`)
      .then(purchases => {
          if(!purchases.data || purchases.data.error){
              return false
          }
          return purchases.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getAlbumRoyaltyPurchasesByDate = async (albumId, filter) => {
      return axios.get(`/api/stats/album/${albumId}/${filter}/getRoyaltyPurchases`)
      .then(purchases => {
          if(!purchases.data || purchases.data.error){
              return false
          }
          return purchases.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getEpisodeRoyaltyPurchasesByDate = async (episodeId, filter) => {
      return axios.get(`/api/stats/episode/${episodeId}/${filter}/getRoyaltyPurchases`)
      .then(purchases => {
          if(!purchases.data || purchases.data.error){
              return false
          }
          return purchases.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getSongDigitalPurchasesByDate = async (songId, filter) => {
      return axios.get(`/api/stats/song/${songId}/${filter}/getDigitalPurchases`)
      .then(purchases => {
          if(!purchases.data || purchases.data.error){
              return false
          }
          return purchases.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getAlbumDigitalPurchasesByDate = async (albumId, filter) => {
      return axios.get(`/api/stats/album/${albumId}/${filter}/getDigitalPurchases`)
      .then(purchases => {
          if(!purchases.data || purchases.data.error){
              return false
          }
          return purchases.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
  
    const getCollectionPurchasesByDate = async (collectionId, filter) => {
      return axios.get(`/api/stats/collection/${collectionId}/${filter}/getCollectionPurchases`)
      .then(purchases => {
          if(!purchases.data || purchases.data.error){
              return false
          }
          return purchases.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
    const getPlaylistLikesByDate = async (playlistId, filter) => {
      return axios.get(`/api/stats/playlist/${playlistId}/${filter}/getLikes`)
      .then(likes => {
          if(!likes.data || likes.data.error){
              return false
          }
          return likes.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }
    const getPlaylistPlaysByDate = async (playlistId, filter) => {
      return axios.get(`/api/stats/playlist/${playlistId}/${filter}/getPlays`)
      .then(plays => {
          if(!plays.data || plays.data.error){
              return false
          }
          return plays.data
      }).catch(err => {
          console.error(err)
          return false
      })
  }

  

  return {
    updateMusicPlay,
    newSongId,
    getSong,
    getSongs,
    getTopSongs,
    getSimilarSongs,
    getSimilarAlbums,
    getSimilarAlbumsByGenre,
    getSongLike,
    getSongWatch,
    generateISRCCode,
    getSubscriptionSongs,
    getSubscriptionSongsByName,
    getDownloadableSongs,
    getDownloadableSongsByName,
    getLatestSongs,
    getDownloadableAlbums,
    getDownloadableAlbumsByName,
    getSubscriptionAlbums,
    getSubscriptionAlbumsByName,
    getLatestAlbums,
    getLatestEpisodes,
    getLatestReleases,
    getTopReleases,
    getPlaylist,
    likePlaylist,
    unlikePlaylist,
    getPlaylistLike,
    getDraft,
    deleteDraft,
    deleteAllDrafts,
    deleteAllCollectionDrafts,
    getDraftsByUid,
    getAlbum,
    getAlbums,
    getTopAlbums,
    likeAlbum,
    unlikeAlbum,
    getAlbumLike,
    uploadAlbum,
    getGenre,
    getPodcastGenre,
    getSubgenre,
    getAllGenres,
    getPodcastGenres,
    deleteSong,
    deletePlaylist,
    getEpisode,
    getEpisodes,
    getTrendingPlaylists,
    getNewestPlaylists,
    getSonarMusePlaylists,
    getLatestSonarMusePlaylists,
    getPlaylistsContainingSong,
    getNFTokenCollections,
    getNFTokenCollectionsByName,
    getSimilarCollections,
    getNFTokenCollectionsByAlbum,
    getNFTokenCollectionsBySong,
    getNFTokenCollection,
    getNFTokenCollectionOwners,
    likeNFTokenCollection,
    unlikeNFTokenCollection,
    getNFTokenCollectionLike,
    watchNFTokenCollection,
    unwatchNFTokenCollection,
    searchGenres,
    searchPodcastGenres,
    likeSong,
    unlikeSong,
    watchSong,
    unwatchSong,
    likeEpisode,
    unlikeEpisode,
    watchEpisode,
    getEpisodeLike,
    unwatchEpisode,
    getEpisodeWatch,
    bulkMusicSearch,
    getSongsByUid,
    getAlbumsByUid,
    searchSongsByArtist,
    searchSongsByFArtist,
    searchSongsByName,
    searchSongsbyGenre,
    searchSongsbySubgenre,
    searchAlbumsByArtistName,
    searchAlbumsByArtistId,
    searchAlbumsByName,
    searchEpisodesByName,
    searchEpisodesByPodcasterName,
    searchAlbumsBySong,
    searchPlaylistsByName,
    searchPlaylistsBySong,
    searchPlaylists,
    addPlaylist,
    uploadSong,
    uploadEpisode,
    getSongTitle,
    digitalPurchase,
    mintNft,
    createNFTokenCollection,
    saveNFTokenCollectionAsDraft,
    getDraftCollections,
    toggleMintable,
    getSwerlVouchers,
    validateRoyaltyTokenTicker,
    getMusicRoyalties,
    getMusicRoyaltiesByType,
    getMusicRoyaltyInfo,
    getRoyaltyTokenOwners,
    //createNFTokenAirdrop,
    nfTokenPurchase,
    validateCollectionId,
    searchSongsByNameAndUid,
    searchMintableSongsByNameAndUid,
    searchMintableAlbumsByNameAndUid,
    updateSongDuration,
    updateSong,
    updateAlbum,
    updateEpisode,
    updateCollection,
    getMusicComment,
    getMusicComments,
    downloadMusic,
    requestMusicDownload,
    downloadTokenGatedContent,
    getTopDigitalPurchases,
    getTopNFTokenPurchases,
    createMp3File,
    getLowerVideoResolutions,
    getAlbumDigitalPurchasesByDate,
    getAlbumLikesByDate,
    getAlbumPlaysByDate,
    getAlbumRoyaltyPurchasesByDate,
  getSongDigitalPurchasesByDate,
  getSongLikesByDate,
  getSongPlaysByDate,
  getSongPlaylistAdditionsByDate,
  getEpisodesContainingSongByDate,
  getSongRoyaltyPurchasesByDate,
  getEpisodeLikesByDate,
  getEpisodePlaylistAdditionsByDate,
  getEpisodeRoyaltyPurchasesByDate,
  getEpisodePlaysByDate,
  getCollectionPurchasesByDate,
  getPlaylistPlaysByDate,
  getPlaylistLikesByDate

    // purchaseNFToken
  };
}

export default useMusic;
