import React, { useEffect, useState } from "react";
import ContentWrapper from "../../ContentWrapper";
import {
  ContentContainer,
  ProfileNav,
  ProfileNavLink,
} from "./ProfileContentElements";
import defaultImg from "../../../images/sonar_muse_logo_nav1.png";
import { useAuth } from "../../../contexts/AuthContext";
import { Slider } from "../../Slider";
import useMusic from "../../../hooks/useMusic";
import ProfileSettings from "./ProfileSettings";
import { useUser } from "../../../hooks/useUser";
import { SliderCardDescription } from "../../Slider/SliderElements";
import Chip from "../../Chip";
import ProfilePlaylists from "./ProfilePlaylists";
import {
  Form,
  FormContainer,
  FormContent,
  FormContent1,
  FormH1,
  FormInput,
  FormLabel,
} from "../../CustomForm/CustomFormElements";
import Modal from "../../Modal";
import { CreatePlaylist} from "../../MenuOptions";
import { SLIDER_TYPES } from "../../../utils/Enum";
import ProfileWatchlist from "./ProfileWatchlist";
import { getObjectLength } from "../../../utils";
import ProfileLikes from "./ProfileLikes";
import { NavContainer } from "../../NavLink/NavLinkElements";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "../../Modal/ModalContext";
import MusicBanner from "../../MusicBanner";
import ProfileStats from "./ProfileStats";

const ProfileContent = ({
  displayName,
  background,
  user,
  getUserProfile,
  isFollower,
}) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const {openModal, modalContent, toggleModal} = useModal()
  const { getPlaylist, getSong, getAlbum } = useMusic();
  const {
    getDigitalDownloads,
    getPlaylistsByUser,
    getCollaborativePlaylistsByUser,
    getNFTokens,
    getNFTokens1,
    getUserWatchlist,
    getNFTokenDetails,
    getUserLikes,
  } = useUser({});
  const [currentTab, setTab] = useState("library");
  const [isCollection, setCollectionActive] = useState(true);
  const [isPlaylists, setPlaylistActive] = useState(false);
  const [isWatchlist, setWatchlistActive] = useState(false);
  const [isSettings, setSettingsActive] = useState(false);
  const [isStats, setStatsActive] = useState(false);
  const [isLikes, setLikesActive] = useState(false);
  const nfts = true;
  const albums = true;
  const singles = true;
  const [collection, setCollection] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [digitals, setDigitals] = useState();
  const [playlists, setPlaylists] = useState([]);
  const [collabPlaylists, setCollabPlaylists] = useState([]);
  const [isCollectionLoaded, setCollectionLoaded] = useState(false);
  const [isNFTsLoaded, setNFTsLoaded] = useState(false);
  const [isDigitalsLoaded, setDigitalsLoaded] = useState(false);
  const [isWatchlistLoaded, setWatchlistLoaded] = useState(false);
  const [isLikesLoaded, setLikesLoaded] = useState(false);
  const [watchlist, setWatchlist] = useState({});
  const [likes, setLikes] = useState({});
  const [nftokens, setNfts] = useState([]);
  const [filteredTokens, setFilteredNfts] = useState();
  const slides = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const slides1 = [1, 2];
  const slides2 = [1, 2, 3, 4, 5];
  const slides3 = [1, 2, 3];
  const slides4 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13];

  useEffect(() => {
    getUserWatchlist(user)
      .then((data) => {
        if (data && getObjectLength(data) > 0) {
          setWatchlist(data);
        }
        setWatchlistLoaded(true);
      })
      .catch((err) => {
        setWatchlistLoaded(true);
      });
    return () => {};
  }, [!isWatchlistLoaded]);

  useEffect(() => {
    if (isFollower) {
      getUserLikes(user)
        .then((data) => {
          if (data && getObjectLength(data) > 0) {
            setLikes(data);
          }
          setLikesLoaded(true);
        })
        .catch((err) => {
          setLikesLoaded(true);
        });
    }
    return () => {};
  }, [!isLikesLoaded]);

  useEffect(() => {
    if (window?.location?.search.includes(`tab=`)) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      setCurrentTab(params.get("tab"));
    } else {
      setCurrentTab();
    }
    return () => {};
  },[]);
;

  const setCurrentTab = (tab) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    switch (tab) {
      case "library":
        params.set("tab", tab);
        setTab(tab);
        setCollectionActive(true);
        setSettingsActive(false);
        setStatsActive(false);
        setLikesActive(false);
        setWatchlistActive(false);
        setPlaylistActive(false);
        break;
      case "playlists":
        params.set("tab", tab);
        setTab(tab);
        setPlaylistActive(true);
        setLikesActive(false);
        setStatsActive(false);
        setCollectionActive(false);
        setSettingsActive(false);
        setWatchlistActive(false);
        break;
      case "watchlist":
        params.set("tab", tab);
        setWatchlistActive(true);
        setLikesActive(false);
        setStatsActive(false);
        setPlaylistActive(false);
        setCollectionActive(false);
        setSettingsActive(false);
        setTab(tab);
        break;

      case "likes":
        if (currentUser?.uid === user || isFollower) {
          params.set("tab", tab);
          setLikesActive(true);
          setWatchlistActive(false);
          setStatsActive(false);
          setPlaylistActive(false);
          setCollectionActive(false);
          setSettingsActive(false);
          setTab(tab);
        } else {
          setTab("libarary");
          setCollectionActive(true);
          setStatsActive(false);
          setSettingsActive(false);
          setWatchlistActive(false);
          setLikesActive(false);
          setPlaylistActive(false);
        }

        break;
      case "settings":
        if (currentUser?.uid === user) {
          params.set("tab", tab);
          setSettingsActive(true);
          setWatchlistActive(false);
          setStatsActive(false);
          setLikesActive(false);
          setPlaylistActive(false);
          setCollectionActive(false);
          setTab(tab);
        } else {
          params.set("tab", "library");
          setTab("libarary");
          setCollectionActive(true);
          setSettingsActive(false);
          setStatsActive(false);
          setWatchlistActive(false);
          setLikesActive(false);
          setPlaylistActive(false);
        }

        break;
      case "stats":
        if (currentUser?.uid === user) {
          params.set("tab", tab);
          setStatsActive(true);
          setSettingsActive(false);
          setWatchlistActive(false);
          setLikesActive(false);
          setPlaylistActive(false);
          setCollectionActive(false);
          setTab(tab);
        } else {
          params.set("tab", "library");
          setTab("libarary");
          setCollectionActive(true);
          setStatsActive(false);
          setSettingsActive(false);
          setWatchlistActive(false);
          setLikesActive(false);
          setPlaylistActive(false);
        }

        break;

      default:
        params.delete("tab");
        setTab("libarary");
        setCollectionActive(true);
        setStatsActive(false);
        setSettingsActive(false);
        setWatchlistActive(false);
        setLikesActive(false);
        setPlaylistActive(false);
        break;
    }
    navigate(
      `${params.toString().length > 0 ? `?${params.toString()}` : "#"}`,
      {
        replace: true,
      }
    );
  };

  useEffect(() => {
    setCollectionLoaded(false);
    getDigitalDownloads(user)
      .then((data) => {
        if (data !== false) {
          setCollection((prev) => {
            return [...prev, data?.songs, data?.albums];
          });
          setDigitals(data);
        }
        setDigitalsLoaded(true)
        return;
      })
      .catch((err) => {
        console.error(err);
        setDigitalsLoaded(true)
      });

    getPlaylistsByUser(user, 0)
      .then((pl) => {
        if (!pl) {
          setPlaylists([]);
          return;
        }
        setPlaylists(pl);
        return;
      })
      .catch((err) => {
        console.error(err);
      });

    getCollaborativePlaylistsByUser(user, 0)
      .then((pl) => {
        if (!pl) {
          setCollabPlaylists([]);
          return;
        }
        setCollabPlaylists(pl);
        return;
      })
      .catch((err) => {
        console.error(err);
      });

    /* getNFTokens(user)
      .then((nfts) => {
        //console.log(nfts)
        if (!nfts) {
          setNfts([]);
          return;
        }
        setNfts(nfts);
        setCollection((prev) => {
          return [...prev, ...nfts];
        });setFilteredNfts(nfts.filter(token => token?.nftoken?.metadata?.mintType))
        setCollectionLoaded(true);
        return;
      })
      .catch((err) => {
        console.error(err);
        setCollectionLoaded(true);
      }); */
    getNFTokens1(user)
      .then(async (tokens) => {
        if(tokens?.length > 0){
          let nfts = []
          let requests = []
          for(let i = 0; i < tokens.length; i++){
            requests.push(getNFTokenDetails(tokens[i]?.tokens[0]?.NFTokenID))
        }
        await Promise.all(requests).then((tokens1) => {
          nfts = tokens1.map((t, index) => {
            if(!t?.error && t){
              return {
                ...t,
                ...tokens[index],
                nftoken:{
                 ...t.nftoken ,
                 ...tokens[index].nftoken
                }
                
              }
            }else{
              return tokens[index]
            }
          })
        }).catch(err => {
          console.error(err)
        })
      
        setNfts(nfts);
        setFilteredNfts(nfts.filter(token => token?.nftoken?.metadata?.mintType))
        setCollection((prev) => {
          return [...nfts];
        })
      };
        setCollectionLoaded(true);
        setNFTsLoaded(true)
        return;
      })
      .catch((err) => {
        console.error(err);
        setNFTsLoaded(true)
        setCollectionLoaded(true);
      });

    return () => {};
  }, [user]);

  return (
    <>
      <ContentContainer background={background}>
        <NavContainer>
          <ProfileNav background={background}>
            <ProfileNavLink
              background={background}
              active={isCollection}
              onClick={() => setCurrentTab("library")}
            >
              Library
            </ProfileNavLink>
            <ProfileNavLink
              background={background}
              active={isPlaylists}
              onClick={() => setCurrentTab("playlists")}
            >
              Playlists
            </ProfileNavLink>
            <ProfileNavLink
              background={background}
              active={isWatchlist}
              onClick={() => setCurrentTab("watchlist")}
            >
              Watchlist
            </ProfileNavLink>
            {(currentUser?.uid === user || isFollower) && (
              <ProfileNavLink
                background={background}
                active={isLikes}
                onClick={() => setCurrentTab("likes")}
              >
                Likes
              </ProfileNavLink>
            )}
            {currentUser?.uid === user && (
              <><ProfileNavLink
                background={background}
                active={isSettings}
                onClick={() => setCurrentTab("settings")}
              >
                Settings
              </ProfileNavLink>
            
              <ProfileNavLink
                background={background}
                active={isStats}
                onClick={() => setCurrentTab("stats")}
              >
                Statistics
              </ProfileNavLink></>
            )}
          </ProfileNav>{" "}
        </NavContainer>
        {isCollection && (
          <>
          
            <ContentWrapper
              heading={
                currentUser?.uid === user
                  ? "My Library"
                  : `${displayName}'s Library`
              }
            >
              {/* <MusicBanner text={<>User's Theme Music</>}/> */}

              {!isDigitalsLoaded && 
              <ContentWrapper heading={'Digital Purchases'}>
                  <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>
                </ContentWrapper>}
              {isDigitalsLoaded && ((digitals?.albums?.length > 0) || (digitals?.songs?.length > 0)) && 
              <ContentWrapper heading={'Digital Purchases'}>
             {digitals?.albums?.length > 0 && (
              <ContentWrapper heading={"Digital Albums"}>
                <Slider
                  type={SLIDER_TYPES.ALBUM}
                  id={"albums"}
                  heightScroll
                  slides={digitals?.albums}
                  background={background}
                />
              </ContentWrapper>
            )}
            {digitals?.songs?.length > 0 && (
              <ContentWrapper heading={"Digital Songs"}>
                <Slider
                  type={SLIDER_TYPES.SONG}
                  id={"songs"}
                  heightScroll
                  slides={digitals?.songs}
                  background={background}
                />
              </ContentWrapper>
            )}</ContentWrapper>}
            {!isNFTsLoaded && 
              <ContentWrapper heading={"NFToken Collections"}>
                  <Slider type={SLIDER_TYPES.PURCHASE_SKELETON}/>
                </ContentWrapper>}
            {isNFTsLoaded && nftokens?.length > 0 && (
              <ContentWrapper
                heading={"NFToken Collections"}
                subheading={showAll ? 'Filter Music NFTokens' : `Show All`}
                onClick={() => setShowAll(!showAll)}
              >
                {!showAll && <Slider
                  type={SLIDER_TYPES.NFTOKEN_COLLECTIONS}
                  id={"nft collections"}
                  heightScroll={true}
                  slides={ filteredTokens}
                  background={background}
                />}
                {showAll && <Slider
                  type={SLIDER_TYPES.NFTOKEN_COLLECTIONS}
                  id={"nft collections"}
                  heightScroll={true}
                  slides={nftokens }
                  background={background}
                />}
                
              </ContentWrapper>
            )}

           </ContentWrapper>
          </>
        )}

        {isWatchlist && (
          <>
            <ContentWrapper
              heading={
                currentUser?.uid === user
                  ? "My Watchlist"
                  : `${displayName}'s Watchlist`
              }
            >
              <ProfileWatchlist
                isLoaded={isWatchlistLoaded}
                watchlist={watchlist}
                userId={user}
              />
            </ContentWrapper>
          </>
        )}

        {isLikes && (
          <>
            <>
              <ContentWrapper
                heading={
                  currentUser?.uid === user
                    ? "My Likes"
                    : `${displayName}'s liked items`
                }
              >
                <ProfileLikes isLoaded={isLikesLoaded} likes={likes} />
              </ContentWrapper>
            </>
          </>
        )}

        {isPlaylists && (
          <>
            {currentUser?.uid === user && (
              <Chip label={"Add New"} onChipClick={() => {
                openModal() 
                modalContent(<CreatePlaylist/>)}} />
            )}
            <ContentWrapper
              heading={
                currentUser?.uid === user
                  ? "My Playlists"
                  : `Playlists curated by ${displayName}`
              }
            >
              {(playlists?.length > 0 || collabPlaylists?.length > 0) &&(
                <ProfilePlaylists
                  playlists={playlists}
                  collabPlaylists={collabPlaylists}
                  background={background}
                  userId={user}
                />
              )}
            </ContentWrapper>
          </>
        )}

        {isSettings && (
          <ContentWrapper heading={"Settings"}>
            <ProfileSettings
              getUserProfile={getUserProfile}
              setTab={setCurrentTab}
            />
          </ContentWrapper>
        )}

        {isStats && (
          <ContentWrapper heading={"Settings"}>
            <ProfileStats userId={user}/>
          </ContentWrapper>
        )}
      </ContentContainer>
    </>
  );
};

export default ProfileContent;
