import React from "react";

import { SkeletonPurchaseCardImg } from "../../Skeleton/SkeletonElements";
import { useModal } from "../../Modal/ModalContext";
import MenuOptions, { ArtistSelectorModal, MusicContentMenu } from "../../MenuOptions";
import { CardContainer, CardContent, CardTitle, CreatorInfo, CreatorInfoContent, CreatorInfoWrapper, H6, InfoContentText, TextLink } from "./AlbumCardElements";
import { useAuth } from "../../../contexts/AuthContext";
import ScrollToTop from "../../ScrollToTop";
import ImageUtils from "../../../utils/ImageUtils";
import { Link } from "react-router-dom";
import { getArtistsNames2, getUniqueListBy } from "../../../utils";
import { ARTIST_SEARCH_TYPES } from "../../../utils/Enum";
import { getResizedImage } from "../../../utils/FileUploadsUtils";

const AlbumCard = ({ content, cardRef }) => {
  const { openModal, closeModal, modalContent } = useModal();
  const {currentUser} = useAuth()
  const {goTo, scrollToTop} = ScrollToTop()
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const {options} = MenuOptions()

  return (<CardContainer id={content?.albumId}
    onContextMenu={(e) => {
      e.preventDefault();
      if (content?.albumId && currentUser?.uid) {
        openModal();
        options(content)
      }
    }
  } ref={cardRef} background={content?.color}>
    <div style={{ alignItems: "center", margin: "auto" }}><SkeletonPurchaseCardImg
    onClick={() => content?.albumId ? goTo(`/album/${content?.albumId}`) : goTo("#")}
    src={content?.albumArt ? content?.albumArt : defaultAlbumArt}
  /></div>
  <CardContent>
  <H6>Album</H6>
    <CardTitle>
      <TextLink alt={true}
        to={content?.albumId ? `/album/${content?.albumId}` : "#"}
      >{content?.albumName}</TextLink>
    </CardTitle>
    <CreatorInfoWrapper>
      <CreatorInfo>
        <CreatorInfoContent>
          
          <InfoContentText>
            {" "}<TextLink onClick={() => {
                       if(content?.artists?.length > 1){
                        openModal()
                       return modalContent(<ArtistSelectorModal artists={content?.artists}/>)
                    }
                    return scrollToTop()
                  }}
                  to={
                    content?.artists?.length === 1 ? `/artist/${content?.artists[0].artistId}`: 
                     "#"
                  }>
            {getArtistsNames2(content?.artists, ARTIST_SEARCH_TYPES.NAME)
                    ?.length > 30
                    ? `${getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      ).substring(
                        0,
                        25
                      )}...`
                    : getArtistsNames2(
                        content?.artists,
                        ARTIST_SEARCH_TYPES.NAME
                      )}</TextLink>
          </InfoContentText>
        </CreatorInfoContent>
      </CreatorInfo>
    </CreatorInfoWrapper>
   
  </CardContent>
</CardContainer>
  );
};
export default AlbumCard;
